import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { rejectOrderApi } from './api';

export function useRejectOrder(
  options?: InferMutationOptions<typeof rejectOrderApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'reject-order',
    rejectOrderApi,
    options,
  );

  return {
    rejectOrder: mutate,
    ...rest,
  };
}
