import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { getDeliveryDocumentApi } from './api';

const QUERY_KEY_DELIVERY_DOCUMENT = (deliveryId: string) => [
  'deliveryDocument',
  deliveryId,
];

export function useGetDeliveryDocument(
  deliveryId: string,
  options?: InferQueryOptions<typeof getDeliveryDocumentApi>,
) {
  const { data: deliveryDocumentUrl, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_DELIVERY_DOCUMENT(deliveryId),
    () => getDeliveryDocumentApi(deliveryId),
    options,
  );

  return {
    deliveryDocumentUrl,
    ...rest,
  };
}
