import { sanitize, makeRequest, Service, identify } from 'utils/http';
import { UpdateOrderRequest } from 'trace-backend-sdk';

type GetUpdateOrderApiType = {
  orderID: string;
  orderData: UpdateOrderRequest;
};

export async function updateOrderAPI(data: GetUpdateOrderApiType) {
  return makeRequest(
    Service.updateOrder(
      data.orderID,
      // @ts-expect-error Need to sanitize input, but it returns Dictionary<unknown>
      sanitize(data.orderData),
      await identify(),
    ),
  );
}
