import React from 'react';
import { generateContext } from 'utils/context';

export const [useGlobalState, GlobalStateProvider] = generateContext<{
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}>();

/**
 * Use context here instead of Little State Machine (LSM), because LSM sends the
 * app into an infinite loop, on every helmet state change.
 * */
export function GlobalState({ children }: { children: React.ReactNode }) {
  const [pageTitle, setPageTitle] = React.useState('');

  const value = React.useMemo(
    () => ({
      pageTitle,
      setPageTitle,
    }),
    [pageTitle, setPageTitle],
  );

  return <GlobalStateProvider value={value}>{children}</GlobalStateProvider>;
}
