import * as React from 'react';
import { UsersService } from 'services/users';
import { Forms } from 'components/form-elements';
import { GetDeliveryResponseStatusEnum } from 'trace-backend-sdk';
import { Box, Flex } from '../../../../components/layout';
import { Image } from '../../../../components/Image';
import { Text } from '../../../../components/typography';
import placeholderImage from '../../../../features/warehouses/assets/placeholderWarehouseImage.jpg';

type DeliveryWorkerItemCardProps = {
  name?: string;
  project?: string;
  imageUrl?: string;
  category?: string;
  plannedQuantity?: number;
  deliveredQuantityName?: string;
  heightName?: string;
  widthName?: string;
  lengthName?: string;
  positionName?: string;
  deliveredQuantity?: number;
  position?: string;
  status?: GetDeliveryResponseStatusEnum;
  width?: number;
  length?: number;
};

export const DeliveryWorkerItemCard = ({
  name,
  project,
  category,
  imageUrl,
  deliveredQuantityName,
  positionName,
  plannedQuantity,
  deliveredQuantity,
  position,
  heightName,
  widthName,
  lengthName,
  width,
  length,
  status,
}: DeliveryWorkerItemCardProps) => {
  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(imageUrl);

  const surfaceArea = width && length && width * length * (deliveredQuantity || 1);
  const isDeliveryRejected = status === GetDeliveryResponseStatusEnum.Rejected;
  const isDeliveryDelivered = status === GetDeliveryResponseStatusEnum.Delivered;
  const isDeliveryScheduled = status === GetDeliveryResponseStatusEnum.Scheduled;

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: 3,
        borderRadius: 2,
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 4, alignSelf: 'center' }}>
        <Flex
          sx={{
            width: isDeliveryDelivered ? '240px' : '260px',
            height: isDeliveryDelivered ? '120px' : '180px',
          }}
        >
          <Image
            src={shouldShowFallback ? placeholderImage : image}
            alt={name}
            sx={{
              width: '100%',
              minHeight: '100%',
              borderRadius: 1,
            }}
          />
        </Flex>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Text
              sx={{
                fontSize: 24,
                fontWeight: 500,
                lineHeight: '20px',
                maxWidth: '180px',
              }}
            >
              {name}
            </Text>

            {isDeliveryDelivered ? (
              <Text sx={{ fontSize: 'sm' }}>
                Surface area: {surfaceArea}m<sup>2</sup>
              </Text>
            ) : null}
          </Box>
          <Text
            variant="userCardTitle"
            sx={{ fontWeight: 500, fontSize: 'md' }}
          >
            {project}
          </Text>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          justifyContent: 'space-between',
          width: isDeliveryDelivered ? '200px' : 'auto',
        }}
      >
        <Flex gap={2} alignItems="center">
          <Text
            intlId="processDelivery.item.card.plannedQuantity"
            sx={{
              flexShrink: 0,
              fontWeight: 500,
            }}
          />
          <Text> {plannedQuantity}</Text>
        </Flex>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Text
              sx={{
                fontWeight: 500,
              }}
              intlId="processDelivery.item.card.deliveredQuantity"
            />
            {deliveredQuantity || isDeliveryRejected ? (
              <Text>{deliveredQuantity ?? '/'}</Text>
            ) : (
              <Forms.FieldEditText
                name={deliveredQuantityName || ''}
                wrapperSx={{ p: 0, width: '60px' }}
                inputSx={{ p: 1 }}
              />
            )}
          </Box>
          {isDeliveryDelivered ? null : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Text sx={{ width: '60px', fontWeight: 500 }}>Height:</Text>
              <Forms.FieldEditText
                disabled={isDeliveryRejected}
                name={heightName || ''}
                inputSx={{ p: 1 }}
                wrapperSx={{ p: 0, width: '60px' }}
              />
            </Box>
          )}
        </Box>
        {isDeliveryDelivered ? null : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Box sx={{ gap: 2, display: 'flex' }}>
              <Text
                sx={{
                  fontWeight: 500,
                  width: isDeliveryScheduled ? '150px' : 'auto',
                }}
              >
                Width:
              </Text>
              <Forms.FieldEditText
                disabled={isDeliveryRejected}
                name={widthName || ''}
                inputSx={{ p: 1 }}
                wrapperSx={{ p: 0, width: '60px' }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Text sx={{ width: '60px', fontWeight: 500 }}>Length:</Text>

              <Forms.FieldEditText
                disabled={isDeliveryRejected}
                name={lengthName || ''}
                inputSx={{ p: 1 }}
                wrapperSx={{ p: 0, width: '60px' }}
              />
            </Box>
          </Box>
        )}
        <Flex gap={2}>
          <Text
            intlId="processDelivery.item.card.position"
            sx={{
              fontWeight: 500,
              flexShrink: 0,
            }}
          />
          {position || isDeliveryRejected ? (
            <Text>{position ?? '/'}</Text>
          ) : (
            <Forms.FieldEditText
              name={positionName || ''}
              wrapperSx={{ p: 0, width: '100%' }}
              inputSx={{ p: 1 }}
            />
          )}
        </Flex>
        <Text
          variant="cardLabelSmall"
          sx={{
            lineHeight: '16px',
            width: 'fit-content',
            alignSelf: 'flex-end',
          }}
        >
          {category || 'No category'}
        </Text>
      </Box>{' '}
    </Flex>
  );
};
