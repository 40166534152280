import React from 'react';

const LOCAL_STORAGE_KEYS = {
  LANG: 'trace::lang',
  COOKIE_CONSENT: 'trace::cookie-consent',
  FAILED_SETUP_INTENT_ID: 'trace::failed-setup-intent-id',
  PAYMENTS_SELECTED_PRICES: 'trace::selected-prices',
  PAYMENTS_CURRENT_STEP: 'trace::current-step',
  ASSUMED_EMAILS: 'trace::assumed-emails',
  SELECTED_DELIVERY_ITEMS: 'trace::selected-delivery-items',
  DELIVERY_FORM_DATA: 'trace::delivery-form-data',
  ORDER_FORM_DATA: 'trace::order-form-data',
  SELECTED_ORDER_ITEMS: 'trace::selected-order-items',
  SELECTED_WAREHOUSE: 'trace::selected-warehouse',
};

function useLocalStorage<T>(key: string, initialValue?: T) {
  if (!key) {
    throw new Error('useLocalStorage key must be specified');
  }

  const initializer = React.useRef((key: string) => {
    try {
      const localStorageValue = localStorage.getItem(key);

      if (localStorageValue != null) {
        return JSON.parse(localStorageValue);
      }

      if (initialValue) {
        localStorage.setItem(key, JSON.stringify(initialValue));
      }

      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const [state, setState] = React.useState<T | undefined>(() =>
    initializer.current(key));
  React.useLayoutEffect(() => setState(initializer.current(key)), [key]);

  const set: React.Dispatch<React.SetStateAction<T | undefined>> = React.useCallback(
    (valOrFunc) => {
      try {
        const newState = typeof valOrFunc === 'function'
          ? (valOrFunc as Function)(state)
          : valOrFunc;

        if (newState == null) {
          return;
        }

        const value = JSON.stringify(newState);

        localStorage.setItem(key, value);
        setState(newState);
      } catch (error) {
        // If user is in private mode or has storage restriction
        // localStorage can throw. JSON.stringify can throw, too
      }
    },
    [key, state],
  );

  const remove = React.useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch (error) {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.stringify can throw, too
    }
  }, [key]);

  return [state, set, remove] as const;
}

export { useLocalStorage, LOCAL_STORAGE_KEYS };
