import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getWarehouseItemsApi } from './api';

export const QUERY_KEY_WAREHOUSE_ITEMS = (id: string) => ['warehouseItems', id];

export function useGetWarehouseItems(
  id: string,
  options?: InferQueryOptions<typeof getWarehouseItemsApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_WAREHOUSE_ITEMS(id),
    () => getWarehouseItemsApi({ id }),
    options,
  );

  return {
    warehouseItems: data,
    ...rest,
  };
}
