import { Text } from 'components/typography';
import * as ReactRouterDom from 'react-router-dom';
import * as React from 'react';
import { WorkspaceService } from 'services/workspace';
import { Routing } from 'global/routing';
import * as _ from 'lodash-es';
import { Button } from 'theme-ui';
import { useGlobalState } from '../../seo/GlobalState';

export function TopBarTitle() {
  const location = ReactRouterDom.useLocation();
  const { pageTitle } = useGlobalState();

  const { workspaceData } = WorkspaceService.useGetWorkspace();

  const [isTextOverflowHidden, setIsTextOverflowHidden] = React.useState(true);

  return (
    <Button
      variant="text"
      sx={{
        display: 'flex',
      }}
      onFocus={() => setIsTextOverflowHidden(false)}
      onBlur={() => setIsTextOverflowHidden(true)}
    >
      <Text
        variant={isTextOverflowHidden ? 'singleLine' : 'multiLine'}
        sx={{
          color: 'white.50',
          fontSize: isTextOverflowHidden ? '16px' : '14px',
          fontWeight: 400,
        }}
      >
        {location.pathname === Routing.HOME.getPath()
          ? workspaceData?.organizationName
          : parseTitle(pageTitle)}
      </Text>
    </Button>
  );
}

function parseTitle(title: string) {
  return _.last(title.split('|'))?.trim() ?? '';
}
