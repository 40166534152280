import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { approveOrderApi } from './api';

export function useApproveOrder(
  options?: InferMutationOptions<typeof approveOrderApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'approve-order',
    approveOrderApi,
    options,
  );

  return {
    approveOrder: mutate,
    ...rest,
  };
}
