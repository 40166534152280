import * as React from 'react';
import { Button, Forms } from 'components/form-elements';
import * as Rhf from 'react-hook-form';
import * as ReactRouter from 'react-router-dom';
import { DatePicker } from 'features/warehouses/components/DatePicker/DatePicker';
import { setHours, setMinutes } from 'date-fns';
import Select, { components, StylesConfig } from 'react-select';
import { WarehouseService } from 'services/warehouse';
import { useNotifications } from 'components/notifications/notifications';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from 'utils/local-storage';
import Joi from 'joi';
import { ValidationSchemas } from 'utils/validation';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  CreateDeliveryRequestItems,
  DeliveryItems,
  Item,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import { getSelectedWarehouse } from 'utils/warehouse-management/get-selected-warehouse';
import { areDatesEqual } from 'utils/warehouse-management/are-dates-equal';
import { areItemsEqual } from 'utils/warehouse-management/are-items-equal';
import { formatDateWithUTCOffset } from 'utils/warehouse-management/format-date-with-utc-offset';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import SuccessMessage from 'features/warehouses/components/SuccessMessage/SuccessMessage';
import * as ReactQuery from 'react-query';
import { QUERY_KEY_DELIVERY } from 'services/warehouse/get-delivery';
import * as Utils from 'utils';
import { UsersService } from 'services/users';
import { Container, Grid, Box, Flex } from '../../components/layout';
import { Text } from '../../components/typography';
import { CreateDeliveryItemCard } from './components/CreateDeliveryItemCard/CreateDeliveryItemCard';
import { DeliveryWorkerView } from './components/DeliveryWorkerView/DeliveryWorkerView';

interface OptionType extends Item {
  label: string;
  value: string;
}

export const CreateDelivery = () => {
  const params = ReactRouter.useParams<{ id: string }>();
  const deliveryId = params.id;

  const [removedDeliveryItemsId, setRemovedDeliveryItemsId] = React.useState<
    string[]
  >([]);

  // Items are used only in edit mode and are not stored in local storage.
  const [editDeliveryItems, setEditDeliveryItems] = React.useState<
    DeliveryItems[]
  >([]);

  // Items are used in create mode and are stored in local storage.
  const [selectedDeliveryItems, setSelectedDeliveryItems] = useLocalStorage<
    DeliveryItems[]
  >(LOCAL_STORAGE_KEYS.SELECTED_DELIVERY_ITEMS, []);

  const { currentUser, isLoading } = Utils.Auth.useCurrentAuthenticatedUser();
  const { active } = UsersService.useGetUsers();

  const workers = active?.filter(
    (user) => user.role === InviteUsersRequestEmailsRoleEnum.User,
  );

  const { allProjects } = WarehouseService.useGetAllProjects({
    enabled:
      currentUser?.signInUserSession.idToken.payload.role
      !== InviteUsersRequestEmailsRoleEnum.User,
  });

  const { delivery, isLoading: isDeliveryLoading } = WarehouseService.useGetDelivery(deliveryId ?? '', {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (deliveryId && data) {
        formMethods.reset({
          name: data.name || '',
          projectId: data.projectId || '',
          workerId: data.workerId || '',
          notes: data.notes || '',
          deliveryDate: data.deliveryDate || '',
          gate: data.gate || '',
          transportationType: data.transportationType || '',
        });
        setEditDeliveryItems(data.items.map((item) => ({ ...item })));
      }
    },
  });

  const { items } = WarehouseService.useGetAllItems({
    enabled:
      currentUser?.signInUserSession.idToken.payload.role
      !== InviteUsersRequestEmailsRoleEnum.User,
  });

  const notifications = useNotifications();
  const selectedWarehouse = getSelectedWarehouse();

  const deliveryItems = deliveryId ? editDeliveryItems : selectedDeliveryItems;

  const [formData, setFormData] = useLocalStorage(
    LOCAL_STORAGE_KEYS.DELIVERY_FORM_DATA,
    {
      name: '',
      projectId: '',
      notes: '',
      deliveryDate: '',
      gate: '',
      transportationType: '',
      workerId: '',
    },
  );

  const history = ReactRouter.useHistory();

  const initialDeliveryDate = deliveryId
    ? delivery?.deliveryDate
    : formData?.deliveryDate;

  const areItemsChanged = !areItemsEqual(
    editDeliveryItems || [],
    delivery?.items ? delivery.items : [],
  );

  const convertedDeliveryDate = React.useMemo(() => {
    return initialDeliveryDate
      ? formatDateWithUTCOffset(new Date(initialDeliveryDate))
      : null;
  }, [initialDeliveryDate]);

  const [deliveryDate, setDeliveryDate] = React.useState<Date | null>(
    convertedDeliveryDate,
  );

  const queryClient = ReactQuery.useQueryClient();

  const {
    updateDelivery,
    isSuccess: isUpdateDeliverySuccess,
    reset,
  } = WarehouseService.useUpdateDelivery({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_DELIVERY(deliveryId)),
      ]);
      setTimeout(() => {
        reset();
      }, 1500);
    },
  });

  const {
    createDelivery,
    isLoading: isCreateDeliveryLoading,
    isSuccess: isCreateDeliverySuccess,
    reset: resetCreateDelivery,
  } = WarehouseService.useCreateDelivery({
    onSuccess: async () => {
      setSelectedDeliveryItems([]);
      setDeliveryDate(null);
      setFormData({
        name: '',
        projectId: '',
        workerId: '',
        notes: '',
        deliveryDate: '',
        gate: '',
        transportationType: '',
      });

      formMethods.reset({
        name: '',
        projectId: '',
        workerId: '',
        notes: '',
        deliveryDate: '',
        gate: '',
        transportationType: '',
      });

      setTimeout(() => {
        resetCreateDelivery();
      }, 1500);
    },
  });

  const formSchema = Joi.object({
    name: ValidationSchemas.CreateDelivery.DELIVERY_NAME,
    projectId: ValidationSchemas.CreateDelivery.PROJECT,
    workerId: ValidationSchemas.CreateDelivery.WORKER,
    notes: ValidationSchemas.CreateDelivery.NOTES,
    deliveryDate: ValidationSchemas.CreateDelivery.DATE,
    gate: ValidationSchemas.CreateDelivery.GATE,
    transportationType: ValidationSchemas.CreateDelivery.TRANSPORTATION_TYPE,
  });

  const formMethods = Rhf.useForm({
    defaultValues: deliveryId
      ? {
        name: delivery?.name ?? '',
        notes: delivery?.notes ?? '',
        projectId: delivery?.projectId ?? '',
        workerId: delivery?.workerId ?? '',
        deliveryDate: delivery?.deliveryDate ?? '',
        gate: '',
        transportationType: '',
      }
      : formData,
    resolver: joiResolver(formSchema),
  });

  const { isDirty, dirtyFields } = Rhf.useFormState({
    control: formMethods.control,
  });

  React.useEffect(() => {
    if (convertedDeliveryDate) {
      setDeliveryDate(convertedDeliveryDate);
    }
  }, [convertedDeliveryDate]);

  const isDateChanged = !areDatesEqual(
    delivery?.deliveryDate
      ? formatDateWithUTCOffset(new Date(delivery?.deliveryDate))
      : '',
    deliveryDate,
  );

  const handleFormChange = (fieldName: string, value: any) => {
    if (!deliveryId) {
      setFormData((prevState) => {
        if (!prevState) {
          return formData;
        }
        return {
          ...prevState,
          [fieldName]: value,
        };
      });
    }
    return value;
  };

  const generateDeliveryButtonText = () => {
    if (deliveryId) {
      return <Text intlId="delivery.editDelivery" variant="button.text" />;
    }

    return <Text intlId="delivery.createDelivery" variant="button.text" />;
  };

  const handleDeliveryItemsChange = (items: DeliveryItems[]) => {
    if (deliveryId) {
      setEditDeliveryItems(items);
    } else {
      setSelectedDeliveryItems(items);
    }
  };

  const handleAddItemClick = () => {
    history.push('/new-item');
  };

  const updateRemovedDeliveryItemsId = (itemId: string) => {
    setRemovedDeliveryItemsId((prevState = []) => [...prevState, itemId]);
  };

  const mappedOptions = React.useMemo(
    () =>
      items?.map((item) => ({
        label: item.name,
        value: item.id,
        ...item,
      })) || [],
    [items],
  );

  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <Flex flexDirection="column">
          <Flex sx={{ fontSize: 'xs', fontStyle: 'italic' }}>
            {props.data.code}, {props.data.company}
          </Flex>
          <Box>{props.label}</Box>
        </Flex>
      </components.Option>
    );
  };

  const customFilterOption = (
    option: { label: string; value: string; data: OptionType },
    inputValue: string,
  ) => {
    if (
      option.label.toLowerCase().includes(inputValue.toLowerCase())
      || option.data.code.toLowerCase().includes(inputValue.toLowerCase())
      || option.data.company.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const customSelectStyles: StylesConfig<OptionType, false> = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #D2D2D2',
      backgroundColor: state.isSelected
        ? '#49a1f4'
        : state.isFocused
          ? '#8cc3f6'
          : 'white',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const onOptionSelect = (
    selectedOption: (Item & { label: string; value: string }) | null,
  ) => {
    if (selectedOption) {
      const isItemAlreadySelected = deliveryItems?.some(
        (item) => item.id === selectedOption.id,
      );

      if (isItemAlreadySelected) {
        notifications.error({
          description: 'delivery.addDelivery.addItem.error',
          durationMs: 2000,
        });
      } else {
        const newItem = {
          ...selectedOption,
          itemId: selectedOption.value,
          deliveryId,
          plannedQuantity: 1,
        };
        if (deliveryId) {
          setEditDeliveryItems((prevState = []) => [...prevState, newItem]);
        } else {
          setSelectedDeliveryItems((prevState = []) => [...prevState, newItem]);
        }

        notifications.success({
          description: 'delivery.addDelivery.addItem.success',
          durationMs: 1000,
        });
      }
    }
  };

  const mapSelectedItems = (
    items: DeliveryItems[],
  ): CreateDeliveryRequestItems[] => {
    return items.map((item) => ({
      id: item.itemId,
      plannedQuantity: item.plannedQuantity ?? 0,
      unit: item.unit ?? '',
      ...(item.height && { height: item.height }),
      ...(item.width && { width: item.width }),
      ...(item.length && { length: item.length }),
    }));
  };

  if (isDeliveryLoading || !currentUser || isLoading) {
    return <InlineLoadingIndicator />;
  }

  const {
    signInUserSession: {
      idToken: { payload },
    },
  } = currentUser;

  return (
    <Container variant="container">
      {payload.role === InviteUsersRequestEmailsRoleEnum.User
      || delivery?.status === 'REJECTED'
      || delivery?.status === 'DELIVERED' ? (
        <DeliveryWorkerView delivery={delivery} />
        ) : isCreateDeliverySuccess || isUpdateDeliverySuccess ? (
          isCreateDeliverySuccess ? (
            <SuccessMessage successMessage="createDelivery.success" />
          ) : (
            <SuccessMessage successMessage="editDelivery.success" />
          )
        ) : (
          <>
            <Grid columns={2}>
              <Select
                options={mappedOptions}
                components={{ Option: CustomOption }}
                styles={customSelectStyles}
                filterOption={customFilterOption}
                onChange={(option) => {
                  onOptionSelect(option);
                }}
                value={null}
                placeholder="Select item"
              />

              <Button
                variant="secondary"
                sx={{ width: '120px', fontSize: 'md', height: '37px' }}
                onClick={handleAddItemClick}
              >
                + <Text intlId="item.newItem" />
              </Button>
            </Grid>

            <Forms.Provider
              onValid={async ({
                name,
                projectId,
                workerId,
                notes,
                deliveryDate,
                gate,
                transportationType,
              }) => {
                const updatedFields = {
                  ...(dirtyFields.name && { name }),
                  ...(dirtyFields.projectId && { projectId }),
                  ...(dirtyFields.workerId && { workerId }),
                  ...(dirtyFields.notes && { notes }),
                  ...(isDateChanged && { deliveryDate }),
                  ...(dirtyFields.gate && { gate }),
                  ...(dirtyFields.transportationType && { transportationType }),
                };
                if (deliveryItems?.length === 0) {
                  notifications.error({
                    description: 'delivery.addDelivery.noItems.error.message',
                    durationMs: 2000,
                  });
                  return;
                }
                const items = deliveryItems && mapSelectedItems(deliveryItems);
                if (deliveryId) {
                  updateDelivery({
                    deliveryID: deliveryId,
                    deliveryData: {
                      ...updatedFields,
                      items: items || [],
                      ...(removedDeliveryItemsId.length > 0 && {
                        itemsToRemove: removedDeliveryItemsId,
                      }),
                    },
                  });
                }

                if (selectedWarehouse.id && items && !deliveryId) {
                  createDelivery({
                    items,
                    warehouseId: selectedWarehouse.id,
                    name,
                    gate,
                    transportationType,
                    projectId,
                    ...(workerId && { workerId }),
                    ...(deliveryDate && { deliveryDate }),
                    ...(notes && { notes }),
                  });
                }
              }}
              name="createDelivery"
              {...formMethods}
            >
              {deliveryItems
              && deliveryItems.map((item) => (
                <CreateDeliveryItemCard
                  deliveryProject={delivery?.projectName}
                  deliveryItems={deliveryItems}
                  handleSelectedDeliveryItemsChange={handleDeliveryItemsChange}
                  updateRemovedDeliveryItemsId={updateRemovedDeliveryItemsId}
                  item={item}
                  key={item.id}
                />
              ))}

              <Grid columns={2}>
                <Forms.FieldEditText
                  labelIntlId="delivery.addDelivery.deliveryName"
                  name="name"
                  required
                  onMapChange={(value) => handleFormChange('name', value)}
                />

                <Forms.SelectField
                  isLabelAlwaysLifted
                  labelIntlId="delivery.addDelivery.project"
                  name="projectId"
                  onMapChange={(event) => {
                    handleFormChange('projectId', event.target.value);
                  }}
                  disabled={deliveryId !== undefined}
                >
                  <option
                    value="" selected
                    disabled hidden
                  >
                    Choose project
                  </option>

                  {allProjects?.map((project) => (
                    <option
                      value={project.id}
                      key={`create-delivery-project-select.option__${project.id}`}
                    >
                      {project.name}
                    </option>
                  ))}
                </Forms.SelectField>

                <Forms.FieldEditText
                  labelIntlId="delivery.addDelivery.gate"
                  name="gate"
                  required
                  onMapChange={(value) => handleFormChange('gate', value)}
                />

                <Forms.FieldEditText
                  labelIntlId="delivery.addDelivery.transportationType"
                  name="transportationType"
                  required
                  onMapChange={(value) =>
                    handleFormChange('transportationType', value)}
                />

                <Forms.SelectField
                  isLabelAlwaysLifted
                  labelIntlId="delivery.addDelivery.worker"
                  name="workerId"
                  onMapChange={(event) =>
                    handleFormChange('workerId', event.target.value)}
                >
                  <option
                    value="" selected
                    disabled hidden
                  >
                    Choose worker
                  </option>
                  {workers?.map((worker) => (
                    <option
                      value={worker.userId}
                      key={`create-delivery-project-select.option__${worker.userId}`}
                    >
                      {worker.user.firstName} {worker.user.lastName}
                    </option>
                  ))}
                </Forms.SelectField>
              </Grid>
              <Forms.FieldEditText
                labelIntlId="delivery.addDelivery.deliveryNotes"
                name="notes"
                onMapChange={(value) => handleFormChange('notes', value)}
              />
              <Grid columns={2}>
                <Text intlId="createDelivery.form.deliveryDate.label" />
                <DatePicker
                  startDate={deliveryDate}
                  setStartDate={(date) => {
                    if (date) {
                      const localDate = new Date(
                        date.getTime() - date.getTimezoneOffset() * 60000,
                      );
                      setDeliveryDate(date);
                      handleFormChange('deliveryDate', localDate.toISOString());
                      formMethods.setValue(
                        'deliveryDate',
                        localDate.toISOString(),
                      );
                    }
                  }}
                  showTimeSelect
                  minTime={setHours(setMinutes(new Date(), 0), 8)}
                  maxTime={setHours(setMinutes(new Date(), 0), 17)}
                />
                <Box />
              </Grid>
              <Grid columns={2}>
                <Button variant="preview" onClick={() => history.goBack()}>
                  <Text intlId="generic.button.goBack" />
                </Button>

                <Forms.SubmitButton
                  sx={{ height: 'auto' }}
                  isLoading={isCreateDeliveryLoading}
                  disabled={
                  !!(
                    deliveryId
                    && !isDirty
                    && !isDateChanged
                    && !areItemsChanged
                  )
                }
                >
                  {generateDeliveryButtonText()}
                </Forms.SubmitButton>
              </Grid>
            </Forms.Provider>
          </>
        )}
    </Container>
  );
};
