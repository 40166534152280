import * as React from 'react';
import * as Rhf from 'react-hook-form';
import * as ReactRouter from 'react-router-dom';
import Joi from 'joi';
import { ValidationSchemas } from 'utils/validation';
import { joiResolver } from '@hookform/resolvers/joi';
import { WarehouseService } from 'services/warehouse';
import { CreateItemRequest } from 'trace-backend-sdk';
import { useNotifications } from 'components/notifications/notifications';
import { Button, Forms } from '../../components/form-elements';
import { Text } from '../../components/typography';
import { Container, Grid } from '../../components/layout';

const itemCategories = [
  'Category 1',
  'Category 2',
  'Category 3',
  'Category 4',
  'Category 5',
];

interface AddItemFormValues extends CreateItemRequest {
  image: File[];
}

const formSchema = Joi.object({
  code: ValidationSchemas.AddItem.ID,
  name: ValidationSchemas.AddItem.NAME,
  company: ValidationSchemas.AddItem.PRODUCING_COMPANY,
  color: ValidationSchemas.AddItem.COLOR,
  weight: ValidationSchemas.AddItem.WEIGHT,
  description: ValidationSchemas.AddItem.DESCRIPTION,
  category: ValidationSchemas.AddItem.CATEGORY,
  image: ValidationSchemas.AddItem.IMAGE,
  unit: ValidationSchemas.AddItem.UNIT,
});

export const ItemDetails: React.FC = () => {
  const notifications = useNotifications();

  const formMethods = Rhf.useForm<AddItemFormValues>({
    defaultValues: {
      code: '',
      name: '',
      company: '',
      color: '',
      weight: '',
      description: '',
      category: itemCategories[0] ?? '',
      image: [],
    },
    resolver: joiResolver(formSchema),
  });
  const { uploadItemImage } = WarehouseService.useUploadItemImage();

  const { createItem, isLoading } = WarehouseService.useCreateItem({
    onSuccess: async (response) => {
      const createdItemId = response?.itemId;
      const imageFile = formMethods.getValues('image')[0];
      if (imageFile) {
        await Promise.all([
          uploadItemImage({
            itemId: createdItemId,
            itemImage: imageFile,
          }),
        ]);
      }
      formMethods.reset();
      notifications.success({
        description: 'createItem.successMessage',
        durationMs: 4000,
      });
    },
  });
  const history = ReactRouter.useHistory();

  return (
    <Container variant="spacer">
      <Text variant="headline2">Item details</Text>
      <Forms.Provider
        onValid={async ({
          code,
          name,
          company,
          color,
          weight,
          description,
          category,
        }) => {
          createItem({
            code,
            name,
            company,
            color,
            weight,
            description,
            category,
          });
        }}
        name="itemDetails"
        {...formMethods}
      >
        <Forms.FieldEditText
          onMapChange={(value) => value.trim()}
          labelIntlId="createItem.form.input.id.label"
          name="code"
          required
        />

        <Forms.FieldEditText
          labelIntlId="createItem.form.input.name.label"
          name="name"
          required
        />

        <Forms.FieldEditText
          labelIntlId="createItem.form.input.producingCompany.label"
          name="company"
          required
        />

        <Forms.FieldEditText
          labelIntlId="createItem.form.input.color.label"
          name="color"
        />

        <Forms.FieldEditText
          labelIntlId="createItem.form.input.weight.label"
          name="weight"
        />

        <Forms.FieldEditText
          onMapChange={(value) => value.slice(0, 50)}
          labelIntlId="createItem.form.input.description.label"
          name="description"
        />

        <Forms.FieldEditText
          onMapChange={(value) => value.slice(0, 50)}
          labelIntlId="createItem.form.input.unit.label"
          name="unit"
        />

        <Forms.SelectField
          labelIntlId="createItem.form.input.category.label"
          name="category"
        >
          {itemCategories.map((item) => (
            <option value={item} key={`warehouse-item-select.option__${item}`}>
              {/*{intl.formatMessage({ id: `country.${item}` })}*/}
              {item}
            </option>
          ))}
        </Forms.SelectField>

        <Forms.FieldFileUpload
          isLabelFloated={false}
          labelIntlId="createItem.form.input.image.label"
          name="image"
          accept=".jpg,.jpeg,.png"
        />

        <Grid columns={2}>
          <Button variant="preview" onClick={() => history.goBack()}>
            <Text intlId="generic.button.goBack" />
          </Button>

          <Forms.SubmitButton sx={{ height: 'auto' }} isLoading={isLoading}>
            <Text
              intlId="createItem.form.button.addItem.text"
              variant="button.text"
            />
          </Forms.SubmitButton>
        </Grid>
      </Forms.Provider>
    </Container>
  );
};
