export enum WarehouseOverviewTabOptions {
  Items = 'Items',
  Orders = 'Orders',
  Deliveries = 'Deliveries',
}

export type WarehouseTab = {
  name: WarehouseOverviewTabOptions;
  component: JSX.Element;
};
