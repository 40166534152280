import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as ReactIntl from 'lib/intl';
import { useGlobalState } from './GlobalState';

type Props = {
  titleIntl?: string;
  intlValues?: Record<string, string | number>;
};

export const PageTitle = ({ titleIntl, intlValues }: Props) => {
  const defaultTitle = 'TRACE';
  const intl = ReactIntl.useIntl();
  const translatedPageTitle = intl.formatMessage(
    {
      id: titleIntl,
    },
    intlValues,
  );

  const { setPageTitle } = useGlobalState();

  return (
    <Helmet
      onChangeClientState={(newState) => {
        setPageTitle(newState.title);
      }}
    >
      <title>
        {titleIntl ? `${defaultTitle} | ${translatedPageTitle}` : defaultTitle}
      </title>
    </Helmet>
  );
};
