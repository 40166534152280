import * as React from 'react';
import { useModal } from 'store/modals';
import { breakpoints } from 'utils/breakpoints';
import { ShouldRender } from 'components/ShouldRender';
import { WarehouseService } from 'services/warehouse';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import * as Utils from 'utils';
import { InviteUsersRequestEmailsRoleEnum } from 'trace-backend-sdk';
import { Container, Grid } from '../../components/layout';
import { Text } from '../../components/typography';
import { Button } from '../../components/form-elements';
import { AddWarehouse } from './components/AddWarehouse/AddWarehouse';
import { WarehouseCard } from './components/WarehouseCard/WarehouseCard';

export const Warehouses: React.FC = () => {
  const { openModal, closeModal } = useModal();
  const { currentUser, isLoading } = Utils.Auth.useCurrentAuthenticatedUser();

  const { warehouses, isLoading: areWarehousesLoading } = WarehouseService.useGetAllWarehouses();

  if (!currentUser || isLoading || areWarehousesLoading) {
    return <PageLoadingIndicator />;
  }
  const {
    signInUserSession: {
      idToken: { payload },
    },
  } = currentUser;

  return (
    <Container
      variant="fullWidth"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ShouldRender
        when={payload.role === InviteUsersRequestEmailsRoleEnum.Admin}
      >
        <Button
          variant="secondary"
          sx={{
            width: '300px',
            alignSelf: 'flex-end',
          }}
          onClick={() =>
            openModal({
              modalType: 'wideComponentModal',
              modalProps: {
                children: <AddWarehouse closeModal={closeModal} />,
              },
            })}
        >
          + Add new Warehouse
        </Button>
      </ShouldRender>
      {warehouses?.length !== 0 ? (
        <Grid
          columns={breakpoints({ _: 1, sm: 2 })}
          sx={{
            maxWidth: '1440px',
            gap: 8,
            alignItems: 'center',
          }}
        >
          {warehouses?.map((warehouse) => (
            <WarehouseCard warehouse={warehouse} key={warehouse.id} />
          ))}
        </Grid>
      ) : (
        <>
          <ShouldRender
            when={payload.role === InviteUsersRequestEmailsRoleEnum.Admin}
          >
            <Text
              sx={{
                mt: '100px',
                maxWidth: '500px',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
              intlId="warehouses.admin.noWarehousesFound.message"
            />
          </ShouldRender>
          <ShouldRender
            when={payload.role === InviteUsersRequestEmailsRoleEnum.Client}
          >
            <Text
              sx={{
                mt: '100px',
                maxWidth: '500px',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
              intlId="warehouses.client.noWarehousesFound.message"
            />
          </ShouldRender>
        </>
      )}
    </Container>
  );
};
