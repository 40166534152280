import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { uploadItemImageApi } from './api';

export function useUploadItemImage(
  options?: InferMutationOptions<typeof uploadItemImageApi>,
) {
  const { mutate: uploadItemImage, ...rest } = ReactQuery.useMutation(
    'uploadItemImage',
    uploadItemImageApi,
    options,
  );

  return {
    uploadItemImage,
    ...rest,
  };
}
