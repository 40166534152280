import * as React from 'react';
import { WarehouseService } from 'services/warehouse';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { WarehouseInventoryItemType } from 'pages/WarehouseOverview';
import { Box } from '../../../../components/layout';
import { WarehouseItemCard } from './components/WarehouseItemCard/WarehouseItemCard';

type WarehouseItemsProps = {
  selectedOrderItems: WarehouseInventoryItemType[] | undefined;
  handleSelectedOrderItemsChange: (item: WarehouseInventoryItemType) => void;
  warehouseId: string;
  role: string;
};

export const WarehouseItems = ({
  selectedOrderItems,
  handleSelectedOrderItemsChange,
  warehouseId,
  role,
}: WarehouseItemsProps) => {
  const { warehouseItems, isLoading } = WarehouseService.useGetWarehouseItems(warehouseId);

  if (isLoading) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Box>
      {warehouseItems?.items?.map((warehouseItem) => (
        <WarehouseItemCard
          role={role}
          item={warehouseItem}
          key={warehouseItem.id}
          selectedOrderItems={selectedOrderItems}
          handleSelectedOrderItemsChange={handleSelectedOrderItemsChange}
        />
      ))}
    </Box>
  );
};
