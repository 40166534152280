import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { InferMutationOptions } from '../../../utils/types';
import { QUERY_KEY_ALL_WAREHOUSES } from '../get-all-warehouses';
import { createWarehouseAPI } from './api';

export function useCreateWarehouse(
  options?: InferMutationOptions<typeof createWarehouseAPI>,
) {
  const queryClient = ReactQuery.useQueryClient();

  const { mutate, isLoading, isError, error, isSuccess } = ReactQuery.useMutation('createWarehouse', createWarehouseAPI, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ALL_WAREHOUSES),
      ]);
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });

  return {
    createWarehouse: mutate,
    isLoading,
    isError,
    error: error as ErrorType,
    isSuccess,
  };
}
