import * as React from 'react';
import * as Rhf from 'react-hook-form';
import { Forms } from 'components/form-elements';
import { WarehouseService } from 'services/warehouse';
import { ValidationSchemas } from 'utils/validation';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useNotifications } from 'components/notifications/notifications';
import { CreateWarehouseRequest } from 'trace-backend-sdk';
import { Text } from '../../../../components/typography';
import { Container, Grid } from '../../../../components/layout';
import * as Icons from '../../../../components/icons';
import { Button } from '../../../../components/form-elements';

type AddWarehouseProps = {
  closeModal: () => void;
};

interface AddWarehouseFormValues extends CreateWarehouseRequest {
  image: File[];
}

const formSchema = Joi.object({
  name: ValidationSchemas.AddWarehouse.NAME,
  address: ValidationSchemas.AddWarehouse.ADDRESS,
  city: ValidationSchemas.AddWarehouse.CITY,
  image: ValidationSchemas.AddWarehouse.IMAGE,
});

export function AddWarehouse({ closeModal }: AddWarehouseProps) {
  const notifications = useNotifications();
  const { uploadWarehouseImage } = WarehouseService.useUploadWarehouseImage();

  const { isLoading, createWarehouse } = WarehouseService.useCreateWarehouse({
    onSuccess: async (response) => {
      const createdWarehouseId = response?.warehouseId;
      const imageFile = formMethods.getValues('image')[0];
      if (imageFile) {
        await Promise.all([
          uploadWarehouseImage({
            warehouseId: createdWarehouseId,
            warehouseImage: imageFile,
          }),
        ]);
      }

      closeModal();
      notifications.success({
        description: 'createWarehouse.successMessage',
        durationMs: 3000,
      });
    },
  });

  const formMethods = Rhf.useForm<AddWarehouseFormValues>({
    defaultValues: {
      name: '',
      address: '',
      city: '',
      image: [],
    },
    resolver: joiResolver(formSchema),
  });

  return (
    <Container
      variant="spacer"
      sx={{
        position: 'relative',
        backgroundColor: 'bg.50',
        padding: '20px 40px',
        borderRadius: '8px',
      }}
    >
      <Icons.QuickCaptureSplash size={42} />
      <Text variant="headline2">Add a Warehouse</Text>
      <br />
      <Text variant="cardLabelSmall">
        Please enter the details for the Warehouse.
      </Text>

      <Forms.Provider
        onValid={async ({ name, address, city }) => {
          createWarehouse({ name, address, city });
        }}
        name="addEditWarehouse"
        {...formMethods}
      >
        <Grid columns={2}>
          <Forms.FieldEditText
            labelIntlId="createWarehouse.form.input.name.label"
            name="name"
            required
          />
          <Forms.FieldEditText
            labelIntlId="createWarehouse.form.input.city.label"
            name="city"
            required
          />
        </Grid>

        <Forms.FieldEditText
          labelIntlId="createWarehouse.form.input.address.label"
          name="address"
          required
        />

        <Forms.FieldFileUpload
          isLabelFloated={false}
          labelIntlId="createWarehouse.form.input.image.label"
          name="image"
          accept=".jpg,.jpeg,.png"
        />

        <Grid columns={2}>
          <Button variant="preview" onClick={closeModal}>
            <Text intlId="confirmationModal.cancel.button.label" />
          </Button>

          <Forms.SubmitButton sx={{ height: 'auto' }} isLoading={isLoading}>
            <Text
              intlId="confirmationModal.proceed.button.label"
              variant="button.text"
            />
          </Forms.SubmitButton>
        </Grid>
      </Forms.Provider>
    </Container>
  );
}
