import { AnimatedCheckmark } from 'components/icons';
import { Box, Flex } from 'components/layout';
import { LocaleKey } from 'translations';
import { Text } from '../../../../components/typography';

type SuccessMessage = {
  successMessage: LocaleKey;
  animationAndTextColor?: string;
};

export default function SuccessMessage({
  successMessage,
  animationAndTextColor,
}: SuccessMessage) {
  return (
    <Flex
      flexDirection="column"
      sx={{
        width: 'fit-content',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box sx={{ width: 128 }}>
        <AnimatedCheckmark strokeColor={animationAndTextColor ?? '#3DDC97'} />
      </Box>

      <Text
        sx={{ color: animationAndTextColor ?? '' }}
        intlId={successMessage}
      />
    </Flex>
  );
}
