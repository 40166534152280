import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { uploadWarehouseImageApi } from './api';

export function useUploadWarehouseImage(
  options?: InferMutationOptions<typeof uploadWarehouseImageApi>,
) {
  const { mutate: uploadWarehouseImage, ...rest } = ReactQuery.useMutation(
    'uploadWarehouseImage',
    uploadWarehouseImageApi,
    options,
  );

  return {
    uploadWarehouseImage,
    ...rest,
  };
}
