import Joi from 'joi';

export const NAME = Joi.string().required().messages({
  'string.empty': 'createWarehouse.form.input.name.error.required',
  'any.required': 'createWarehouse.form.input.name.error.required',
});

export const CITY = Joi.string().required().messages({
  'string.empty': 'createWarehouse.form.input.city.error.required',
  'any.required': 'createWarehouse.form.input.city.error.required',
});

export const ADDRESS = Joi.string().required().messages({
  'string.empty': 'createWarehouse.form.input.address.error.required',
  'any.required': 'createWarehouse.form.input.address.error.required',
});

export const IMAGE = Joi.any();
