import Joi from 'joi';

export const ID = Joi.string().required().messages({
  'string.empty': 'createItem.form.input.id.error.required',
  'any.required': 'createItem.form.input.id.error.required',
});

export const NAME = Joi.string().required().messages({
  'string.empty': 'createItem.form.input.name.error.required',
  'any.required': 'createItem.form.input.name.error.required',
});

export const PRODUCING_COMPANY = Joi.string().required().messages({
  'string.empty': 'createItem.form.input.producingCompany.error.required',
  'any.required': 'createItem.form.input.producingCompany.error.required',
});

export const UNIT = Joi.any();

export const COLOR = Joi.any();

export const WEIGHT = Joi.any();

export const DESCRIPTION = Joi.any();

export const CATEGORY = Joi.any();

export const IMAGE = Joi.any();
