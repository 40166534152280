import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getOrderApi } from './api';

export const QUERY_KEY_ORDER = (id: string) => ['order', id];

export function useGetOrder(
  id: string,
  options?: InferQueryOptions<typeof getOrderApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ORDER(id),
    () => getOrderApi({ id }),
    options,
  );

  return {
    order: data,
    ...rest,
  };
}
