import * as React from 'react';
import { LocaleKey } from 'translations';
import {
  WarehouseInventoryItem,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import { UsersService } from 'services/users';
import { WarehouseInventoryItemType } from 'pages/WarehouseOverview';
import { Flex } from '../../../../../../components/layout';
import { Text } from '../../../../../../components/typography';
import { Button } from '../../../../../../components/form-elements';
import { Image } from '../../../../../../components/Image';
import { ShouldRender } from '../../../../../../components/ShouldRender';
import placeholderImage from '../../../../../../features/warehouses/assets/placeholderWarehouseImage.jpg';

type WarehouseItemCardProps = {
  item: WarehouseInventoryItem;
  selectedOrderItems: WarehouseInventoryItemType[] | undefined;
  handleSelectedOrderItemsChange: (item: WarehouseInventoryItemType) => void;
  role: string;
};

type WarehouseItemCardListRowProps = {
  title: LocaleKey;
  value?: string | number;
};

export const WarehouseItemCard = ({
  item,
  selectedOrderItems,
  handleSelectedOrderItemsChange,
  role,
}: WarehouseItemCardProps) => {
  const { image: itemImage } = item;
  const { name } = item;
  const { description } = item;
  const { projectName } = item;
  const { quantity } = item;
  const { category } = item;
  const { company } = item;
  const { color } = item;
  const { weight } = item;
  const { position } = item;
  const { clientName } = item;
  const { zone } = item;
  const { floor } = item;

  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(itemImage);

  const checkIfActiveOrderHasItem = () =>
    selectedOrderItems?.some(
      (activeOrderItem) => activeOrderItem.id === item.id,
    );

  const handleAddItemToActiveOrder = () => {
    const mappedItems = {
      name: item.name,
      id: item.id,
      itemId: item.itemId,
      position: item.position || '',
      stock: item.quantity,
      projectName: item.projectName,
      orderedQuantity: 1,
      projectId: item.projectId,
    };

    handleSelectedOrderItemsChange(mappedItems);
  };

  const WarehouseItemCardListRow = ({
    title,
    value,
  }: WarehouseItemCardListRowProps) => {
    return (
      <li>
        <Flex>
          <Text
            sx={{
              fontSize: 'md',
            }}
            intlId={title}
          />
          <Text sx={{ fontSize: 'md', mr: 1 }}>:</Text>
          <Text variant="body2">{value || '/'}</Text>
        </Flex>
      </li>
    );
  };

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: '20px',
        my: '20px',
        minHeight: '200px',
        borderRadius: 2,
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'transparent',
          width: '200px',
          mr: '20px',
          flexShrink: 0,
        }}
        alignItems="flex-start"
      >
        <Image
          src={shouldShowFallback ? placeholderImage : image}
          alt={name}
          sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </Flex>

      <Flex
        sx={{ mr: '20px' }}
        flexDirection="column"
        justifyContent="space-between"
        gap={2}
      >
        <Flex flexDirection="column" gap={1}>
          <Flex gap={1} alignContent="center">
            <Text
              sx={{
                fontSize: 20,
                fontWeight: 500,
                lineHeight: '20px',
              }}
            >
              {name}
            </Text>

            <ShouldRender
              when={role === InviteUsersRequestEmailsRoleEnum.Admin}
            >
              <Text
                sx={{
                  fontSize: 'md',
                  alignSelf: 'flex-end',
                  fontStyle: 'italic',
                }}
              >
                ({position})
              </Text>
            </ShouldRender>
          </Flex>
          <Text variant="body2">{description || 'No description'}</Text>

          <ul style={{ paddingLeft: '20px' }}>
            <WarehouseItemCardListRow
              title="item.itemDetails.zone"
              value={zone}
            />

            <WarehouseItemCardListRow
              title="item.itemDetails.floor"
              value={floor}
            />

            <WarehouseItemCardListRow
              title="item.itemDetails.producer"
              value={company}
            />

            <WarehouseItemCardListRow
              title="createItem.form.input.color.label"
              value={color}
            />

            <WarehouseItemCardListRow
              title="createItem.form.input.weight.label"
              value={weight}
            />
          </ul>
        </Flex>

        <Text variant="userCardTitle" sx={{ fontWeight: 500, fontSize: 'md' }}>
          {clientName}, {projectName}
        </Text>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        gap={2}
        sx={{ width: '180px', flexShrink: 0 }}
      >
        <Text variant="caption" sx={{ fontSize: '16px' }}>
          In Stock: <Text sx={{ fontWeight: 600 }}>{quantity}</Text>
        </Text>
        <Flex alignItems="flex-end" flexDirection="column">
          <Button
            variant="primary"
            disabled={checkIfActiveOrderHasItem() || quantity === 0}
            onClick={handleAddItemToActiveOrder}
            sx={{
              width: '150px',
              fontSize: 'md',
            }}
          >
            Add to Order
          </Button>
        </Flex>
        <Flex justifyContent="flex-end">
          <Text variant="cardLabelSmall" sx={{ mt: '10px' }}>
            {category}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
