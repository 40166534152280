import * as React from 'react';
import * as Rhf from 'react-hook-form';
import * as _ from 'lodash-es';
import { Button } from 'components/form-elements';
import { StyleObject } from 'theming';
import { LocaleKey } from 'translations';
import { ButtonVariants } from '../../../../theming/variants/buttons';

type SubmitButtonProps = {
  children?: React.ReactNode | React.ReactNodeArray;
  isLoading?: boolean;
  sx?: StyleObject;
  disabled?: boolean;
  intlId?: LocaleKey;
  'data-testid'?: string;
  variant?: ButtonVariants;
};

export function SubmitButton({
  children,
  isLoading,
  sx,
  disabled,
  intlId,
  variant,
  ...props
}: SubmitButtonProps) {
  const formMethods = Rhf.useFormContext();
  const { errors } = Rhf.useFormState({
    control: formMethods.control,
  });

  const hasErrors = !_.isEmpty(errors);

  return (
    <Button
      data-testid={props['data-testid']}
      disabled={hasErrors || isLoading || disabled}
      type="submit"
      intlId={intlId}
      isLoading={isLoading}
      variant={variant || 'primary'}
      sx={{
        height: '50px',
        fontSize: 'xl',
        py: 0,
        px: 10,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 2,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
}
