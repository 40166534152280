import Joi from 'joi';

export const ORDER_NAME = Joi.string().required().messages({
  'string.empty': 'createOrder.form.input.orderName.error.required',
  'any.required': 'createOrder.form.input.orderName.error.required',
});

export const ADDRESS = Joi.string().required().messages({
  'string.empty': 'createOrder.form.input.address.error.required',
  'any.required': 'createOrder.form.input.address.error.required',
});

export const EMAIL = Joi.string().required().messages({
  'string.empty': 'createOrder.form.input.email.error.required',
  'any.required': 'createOrder.form.input.email.error.required',
});

export const PHONE_NUMBER = Joi.string()
  .pattern(/^[0-9()+\-\s./]+$/)
  .required()
  .messages({
    'any.required': 'processDelivery.form.input.error.required',
    'string.pattern.base':
      'createOrder.form.input.phoneNumber.error.invalidCharacters',
  });

export const NOTES = Joi.any();

export const WORKER = Joi.any();

export const DATE = Joi.any();
