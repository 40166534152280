import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getAllDeliveriesApi } from './api';

export const QUERY_KEY_ALL_DELIVERIES = ['allDeliveries'];

export function useGetAllDeliveries(
  options?: InferQueryOptions<typeof getAllDeliveriesApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ALL_DELIVERIES,
    getAllDeliveriesApi,
    options,
  );

  return {
    allDeliveries: data?.deliveries,
    ...rest,
  };
}
