import * as React from 'react';
import { UsersService } from 'services/users';
import { Forms } from 'components/form-elements';
import {
  GetOrderResponseStatusEnum,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import { Box, Flex } from '../../../../components/layout';
import { Image } from '../../../../components/Image';
import { Text } from '../../../../components/typography';
import placeholderImage from '../../../../features/warehouses/assets/placeholderWarehouseImage.jpg';

type OrderWorkerItemCardProps = {
  name?: string;
  project?: string;
  imageUrl?: string;
  category?: string;
  orderedQuantity: number;
  packedQuantity?: number;
  position?: string;
  status?: GetOrderResponseStatusEnum;
  packedQuantityName?: string;
  role?: string;
};

export const OrderWorkerItemCard = ({
  name,
  project,
  imageUrl,
  orderedQuantity,
  position,
  category,
  status,
  packedQuantityName,
  packedQuantity,
  role,
}: OrderWorkerItemCardProps) => {
  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(imageUrl);

  const isOrderApproved = status === GetOrderResponseStatusEnum.Approved;
  const isOrderRejected = status === GetOrderResponseStatusEnum.Rejected;

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: 3,
        borderRadius: 2,
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 4, alignSelf: 'center' }}>
        <Flex
          sx={{
            width: '240px',
            height: '120px',
          }}
        >
          <Image
            src={shouldShowFallback ? placeholderImage : image}
            alt={name}
            sx={{
              width: '100%',
              minHeight: '100%',
              borderRadius: 1,
            }}
          />
        </Flex>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Text
              sx={{
                fontSize: 24,
                fontWeight: 500,
                lineHeight: '20px',
                maxWidth: '180px',
              }}
            >
              {name}
            </Text>
          </Box>
          <Text
            variant="userCardTitle"
            sx={{ fontWeight: 500, fontSize: 'md' }}
          >
            {project}
          </Text>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          justifyContent: 'space-between',
          width: '220px',
        }}
      >
        <Flex gap={2} alignItems="center">
          <Text
            intlId="processOrder.item.card.orderedQuantity"
            sx={{
              flexShrink: 0,
              fontWeight: 500,
            }}
          />
          <Text> {orderedQuantity}</Text>
        </Flex>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Text
              sx={{
                fontWeight: 500,
              }}
              intlId="processOrder.item.card.packedQuantity"
            />
            {packedQuantity
            || (isOrderApproved
              && role !== InviteUsersRequestEmailsRoleEnum.User)
            || isOrderRejected ? (
              <Text>{packedQuantity ?? '/'}</Text>
              ) : (
                <Forms.FieldEditText
                  name={packedQuantityName || ''}
                  wrapperSx={{ p: 0, width: '60px' }}
                  inputSx={{ p: 1 }}
                />
              )}
          </Box>
        </Box>

        <Flex gap={2}>
          <Text
            intlId="processDelivery.item.card.position"
            sx={{
              fontWeight: 500,
              flexShrink: 0,
            }}
          />
          {position && <Text>{position ?? '/'}</Text>}
        </Flex>
        <Text
          variant="cardLabelSmall"
          sx={{
            lineHeight: '16px',
            width: 'fit-content',
            alignSelf: 'flex-end',
          }}
        >
          {category || 'No category'}
        </Text>
      </Box>{' '}
    </Flex>
  );
};
