import * as React from 'react';
import { CreateEventItemCard } from 'features/warehouses/components/CreateEventItemCard/CreateEventItemCard';
import { OrderItems, WarehouseInventoryItem } from 'trace-backend-sdk';

type CreateOrderItemCardProps = {
  item: OrderItems;
  orderItems: OrderItems[];
  warehouseItems?: WarehouseInventoryItem[];
  handleSelectedOrderItemsChange: (items: OrderItems[]) => void;
  updateRemovedOrderItemsId: (id: string) => void;
  projectName?: string;
};

export const CreateOrderItemCard = ({
  item,
  orderItems,
  warehouseItems,
  handleSelectedOrderItemsChange,
  updateRemovedOrderItemsId,
  projectName,
}: CreateOrderItemCardProps) => {
  const itemIndex = orderItems.findIndex(
    (currentItem) => currentItem.id === item.id,
  );

  const [itemQuantity, setItemQuantity] = React.useState<number>(
    item.orderedQuantity || 1,
  );

  const matchedItem = warehouseItems?.find(
    (warehouseItem: {
      itemId: string;
      position?: string;
      projectName?: string;
    }) =>
      warehouseItem.itemId === item.itemId
      && warehouseItem.position === item.position
      && warehouseItem.projectName === projectName,
  );

  const updateActiveOrderGlobalItemQuantity = (quantity: number) => {
    if (orderItems) {
      const updatedActiveOrderGlobal = [...orderItems];
      if (updatedActiveOrderGlobal[itemIndex]) {
        updatedActiveOrderGlobal[itemIndex].orderedQuantity = quantity;
      }
      handleSelectedOrderItemsChange(updatedActiveOrderGlobal);
    }
  };

  const handleItemQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.valueAsNumber;
    setItemQuantity(quantity);
    updateActiveOrderGlobalItemQuantity(quantity);
  };

  const handleItemQuantityBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.valueAsNumber;
    if (quantity === 0 || (matchedItem && quantity > matchedItem.quantity)) {
      setItemQuantity(1);
      updateActiveOrderGlobalItemQuantity(1);
    }
  };

  const handleRemoveItem = () => {
    const text = 'Are you sure you want to remove this item from the order?';
    // eslint-disable-next-line no-alert
    if (window.confirm(text) && item.itemId) {
      const updatedActiveOrderGlobal = orderItems.filter(
        (orderItem) => orderItem.id !== item.id,
      );
      handleSelectedOrderItemsChange(updatedActiveOrderGlobal);
      updateRemovedOrderItemsId(item.id);
    }
  };

  const handlePlusButtonClick = () => {
    const increasedByOne = itemQuantity + 1;
    if (matchedItem && increasedByOne <= matchedItem?.quantity) {
      setItemQuantity(increasedByOne);
      updateActiveOrderGlobalItemQuantity(increasedByOne);
    }
  };

  const handleMinusButtonClick = () => {
    const decreasedByOne = itemQuantity - 1;
    if (decreasedByOne >= 1) {
      setItemQuantity(decreasedByOne);
      updateActiveOrderGlobalItemQuantity(decreasedByOne);
    }
  };

  return (
    <CreateEventItemCard
      imageUrl={matchedItem?.image}
      name={matchedItem?.name}
      category={matchedItem?.category}
      project={projectName}
      stock={matchedItem && matchedItem?.quantity}
      plannedQuantity={itemQuantity}
      handleMinusButtonClick={handleMinusButtonClick}
      handleItemQuantityChange={handleItemQuantityChange}
      handlePlusButtonClick={handlePlusButtonClick}
      handleRemoveItem={handleRemoveItem}
      handleItemQuantityBlur={handleItemQuantityBlur}
    />
  );
};
