import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getAllProjectsApi } from './api';

export const QUERY_KEY_ALL_PROJECTS = ['allProjects'];

export function useGetAllProjects(
  options?: InferQueryOptions<typeof getAllProjectsApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ALL_PROJECTS,
    getAllProjectsApi,
    options,
  );

  return {
    allProjects: data?.projects,
    ...rest,
  };
}
