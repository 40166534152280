type ComparableItem = {
  id: string;
  orderedQuantity?: number;
  plannedQuantity?: number;
  unit?: string;
  width?: number;
  height?: number;
  length?: number;
};

export const areItemsEqual = (
  items1: ComparableItem[],
  items2: ComparableItem[],
): boolean => {
  if (items1.length !== items2.length) return false;

  const sortedItems1 = [...items1].sort((a, b) => a.id.localeCompare(b.id));
  const sortedItems2 = [...items2].sort((a, b) => a.id.localeCompare(b.id));

  return sortedItems1.every((item, index) => {
    const compareItem = sortedItems2[index];
    const quantity1 = item.plannedQuantity ?? item.orderedQuantity;
    const quantity2 = compareItem.plannedQuantity ?? compareItem.orderedQuantity;

    return (
      item.id === compareItem.id
      && quantity1 === quantity2
      && item.unit === compareItem.unit
      && item.height === compareItem.height
      && item.width === compareItem.width
      && item.length === compareItem.length
    );
  });
};
