import * as React from 'react';
import { CreateEventItemCard } from 'features/warehouses/components/CreateEventItemCard/CreateEventItemCard';
import { DeliveryItems } from 'trace-backend-sdk';

type CreateDeliveryItemCardProps = {
  item: DeliveryItems;
  deliveryProject?: string;
  deliveryItems: DeliveryItems[] | undefined;
  handleSelectedDeliveryItemsChange: (items: DeliveryItems[]) => void;
  updateRemovedDeliveryItemsId: (id: string) => void;
};

export const CreateDeliveryItemCard = ({
  item,
  deliveryItems,
  deliveryProject,
  handleSelectedDeliveryItemsChange,
  updateRemovedDeliveryItemsId,
}: CreateDeliveryItemCardProps) => {
  const { id } = item;
  const itemIndex = deliveryItems?.findIndex((currentItem) => currentItem.id === id) ?? -1;

  const [itemQuantity, setItemQuantity] = React.useState<number>(
    item.plannedQuantity,
  );

  const updateActiveDeliveryItem = (
    field: 'plannedQuantity' | 'width' | 'height' | 'length',
    value: string | number,
  ) => {
    if (itemIndex >= 0 && deliveryItems) {
      const updatedActiveDeliveryGlobal = [...deliveryItems];
      if (updatedActiveDeliveryGlobal[itemIndex]) {
        (updatedActiveDeliveryGlobal[itemIndex] as any)[field] = value;
      }
      handleSelectedDeliveryItemsChange(updatedActiveDeliveryGlobal);
    }
  };

  const handleItemQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.valueAsNumber;
    setItemQuantity(quantity);
    updateActiveDeliveryItem('plannedQuantity', quantity);
  };

  const handleRemoveItem = () => {
    const text = 'Are you sure you want to remove this item from the delivery?';
    // eslint-disable-next-line no-alert
    if (window.confirm(text) && deliveryItems) {
      const updatedActiveDeliveryGlobal = deliveryItems.filter(
        (item) => item.id !== id,
      );
      handleSelectedDeliveryItemsChange(updatedActiveDeliveryGlobal);
      updateRemovedDeliveryItemsId(item.itemId);
    }
  };

  const handleItemQuantityBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.valueAsNumber;
    if (quantity === 0) {
      setItemQuantity(1);
      updateActiveDeliveryItem('plannedQuantity', 1);
    }
  };

  const handlePlusButtonClick = () => {
    const increasedByOne = itemQuantity + 1;
    setItemQuantity(increasedByOne);
    updateActiveDeliveryItem('plannedQuantity', increasedByOne);
  };

  const handleMinusButtonClick = () => {
    const decreasedByOne = itemQuantity - 1;
    if (decreasedByOne >= 1) {
      setItemQuantity(decreasedByOne);
      updateActiveDeliveryItem('plannedQuantity', decreasedByOne);
    }
  };

  return (
    <CreateEventItemCard
      height={item?.height}
      width={item?.width}
      length={item?.length}
      name={item.name}
      project={deliveryProject}
      imageUrl={item.image}
      category={item.category}
      plannedQuantity={itemQuantity}
      handleItemQuantityBlur={handleItemQuantityBlur}
      handleMinusButtonClick={handleMinusButtonClick}
      handleItemQuantityChange={handleItemQuantityChange}
      handlePlusButtonClick={handlePlusButtonClick}
      handleRemoveItem={handleRemoveItem}
      updateActiveDeliveryItem={updateActiveDeliveryItem}
    />
  );
};
