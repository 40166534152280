export const areDatesEqual = (
  date1: string | Date | null,
  date2: string | Date | null,
) => {
  if (!date1 || !date2) return false;

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getUTCFullYear() === d2.getUTCFullYear()
    && d1.getUTCMonth() === d2.getUTCMonth()
    && d1.getUTCDate() === d2.getUTCDate()
    && d1.getUTCHours() === d2.getUTCHours()
    && d1.getUTCMinutes() === d2.getUTCMinutes()
  );
};
