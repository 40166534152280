import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getAllItemsApi } from './api';

export const QUERY_KEY_ALL_ITEMS = ['allItems'];

export function useGetAllItems(
  options?: InferQueryOptions<typeof getAllItemsApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ALL_ITEMS,
    getAllItemsApi,
    options,
  );

  return {
    items: data?.items,
    ...rest,
  };
}
