import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { updateOrderAPI } from './api';

export function useUpdateOrder(
  options?: InferMutationOptions<typeof updateOrderAPI>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'update-order',
    updateOrderAPI,
    options,
  );

  return {
    updateOrder: mutate,
    ...rest,
  };
}
