import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { updateDeliveryAPI } from './api';

export function useUpdateDelivery(
  options?: InferMutationOptions<typeof updateDeliveryAPI>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'update-delivery',
    updateDeliveryAPI,
    options,
  );

  return {
    updateDelivery: mutate,
    ...rest,
  };
}
