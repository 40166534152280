import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { setHours, setMinutes } from 'date-fns';

export interface DatePickerProps {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  minTime?: Date;
  maxTime?: Date;
  showTimeSelect?: boolean;
}

export function DatePicker({
  startDate,
  setStartDate,
  minTime,
  maxTime,
  showTimeSelect,
}: DatePickerProps) {
  const defaultMinTime = setHours(setMinutes(new Date(), 0), 0);
  const defaultMaxTime = setHours(setMinutes(new Date(), 59), 23);
  const defaultTime = setHours(setMinutes(new Date(), 0), 0);

  const handleTimeChange = (date: Date | null) => {
    if (date && (minTime || maxTime)) {
      const selectedTime = date.getHours() * 60 + date.getMinutes();
      const minTimeSelected = minTime || defaultMinTime;
      const maxTimeSelected = maxTime || defaultMaxTime;
      const minTimeInMinutes = minTimeSelected.getHours() * 60 + minTimeSelected.getMinutes();
      const maxTimeInMinutes = maxTimeSelected.getHours() * 60 + maxTimeSelected.getMinutes();
      const defaultTimeInMinutes = defaultTime.getHours() * 60 + defaultTime.getMinutes();

      if (
        (selectedTime < minTimeInMinutes
          && selectedTime !== defaultTimeInMinutes)
        || (selectedTime > maxTimeInMinutes
          && selectedTime !== defaultTimeInMinutes)
      ) {
        return;
      }
      setStartDate(date);
    } else {
      setStartDate(date);
    }
  };

  return (
    <ReactDatePicker
      showIcon
      selected={startDate}
      onChange={handleTimeChange}
      dateFormat={showTimeSelect ? 'dd-MM-yyyy, HH:mm' : 'dd-MM-yyyy'}
      showTimeSelect={showTimeSelect}
      timeIntervals={30}
      timeFormat="HH:mm"
      calendarStartDay={1}
      minTime={minTime || defaultMinTime}
      maxTime={maxTime || defaultMaxTime}
      minDate={new Date()}
      placeholderText="Select date"
    />
  );
}
