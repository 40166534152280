import React from 'react';
import * as Rhf from 'react-hook-form';
import * as _ from 'lodash-es';

import { Input, InputProps } from 'components/form-elements';
import { Colors } from 'theming/foundations/colors';
import { LabelledFileInput } from 'components/form-elements/labels/LabelledFileInput';
import { LocaleKey } from 'translations';

function composeFieldName(...names: string[]) {
  return names.join('.');
}

export interface FieldFileUploadProps extends InputProps {
  labelIntlId?: LocaleKey;
  backgroundColor?: Colors;
  name: string;
  isLabelFloated?: boolean;
  isAlwaysFloating?: boolean;
}

export const FieldFileUpload: React.FC<FieldFileUploadProps> = ({
  children,
  sx,
  id,
  labelIntlId,
  backgroundColor = 'white.100',
  name,
  required = false,
  accept,
  isLabelFloated = true,
  isAlwaysFloating = false,
  ...props
}) => {
  const [isActive, setIsActive] = React.useState(false);

  const formMethods = Rhf.useFormContext();
  const { errors } = Rhf.useFormState({
    control: formMethods.control,
  });

  const errorMessage: string | undefined = _.get(
    errors,
    composeFieldName(name, 'message'),
  );

  const fieldValues = formMethods.register(name);

  const validateFileExtension = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (accept && !accept.includes(fileExtension || '')) {
        formMethods.setError(name, {
          type: 'manual',
          message: `Unsupported file type: ${fileExtension}`,
        });
      } else {
        formMethods.clearErrors(name);
        if (fieldValues.onChange) {
          fieldValues.onChange(e);
        }
      }
    } else if (!required) {
      formMethods.clearErrors(name);
    }
  };

  return (
    <LabelledFileInput
      backgroundColor={backgroundColor}
      labelIntlId={labelIntlId}
      sx={sx}
      id={id}
      errorMessage={errorMessage}
      isActive={isAlwaysFloating || isActive}
      isFocused={isAlwaysFloating || isActive}
      disabled={props.disabled}
      required={required}
      isLabelFloated={isLabelFloated}
    >
      <Input
        onFocus={() => setIsActive(true)}
        onChange={validateFileExtension}
        id={id}
        sx={{
          backgroundColor,
          px: 4,
        }}
        type="file"
        accept={accept}
        {...props}
        name={fieldValues.name}
        ref={fieldValues.ref}
      >
        {children}
      </Input>
    </LabelledFileInput>
  );
};
