import Joi from 'joi';

export const DELIVERED_QUANTITY = Joi.number()
  .min(0)
  .invalid(0)
  .required()
  .empty('')
  .messages({
    'any.required': 'processDelivery.form.input.error.required',
    'number.base': 'processDelivery.form.input.error.numbersOnly',
    'number.min': 'processDelivery.form.input.error.required',
    'any.invalid': 'processDelivery.form.input.error.required',
  });

export const POSITION = Joi.string().required().messages({
  'string.empty': 'processDelivery.form.input.error.required',
  'any.required': 'processDelivery.form.input.error.required',
});

export const HEIGHT = Joi.number()
  .min(0)
  .invalid(0)
  .required()
  .empty('')
  .messages({
    'any.required': 'processDelivery.form.input.error.required',
    'number.base': 'processDelivery.form.input.error.numbersOnly',
    'number.min': 'processDelivery.form.input.error.required',
    'any.invalid': 'processDelivery.form.input.error.required',
  });

export const WIDTH = Joi.number()
  .min(0)
  .invalid(0)
  .required()
  .empty('')
  .messages({
    'any.required': 'processDelivery.form.input.error.required',
    'number.base': 'processDelivery.form.input.error.numbersOnly',
    'number.min': 'processDelivery.form.input.error.required',
    'any.invalid': 'processDelivery.form.input.error.required',
  });
export const LENGTH = Joi.number()
  .min(0)
  .invalid(0)
  .required()
  .empty('')
  .messages({
    'any.required': 'processDelivery.form.input.error.required',
    'number.base': 'processDelivery.form.input.error.numbersOnly',
    'number.min': 'processDelivery.form.input.error.required',
    'any.invalid': 'processDelivery.form.input.error.required',
  });

export const NOTES = Joi.any();
