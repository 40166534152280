import * as React from 'react';
import { Button, Forms } from 'components/form-elements';
import * as Rhf from 'react-hook-form';
import * as ReactRouter from 'react-router-dom';
import { DatePicker } from 'features/warehouses/components/DatePicker/DatePicker';
import { ValidationSchemas } from 'utils/validation';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from 'utils/local-storage';
import { WarehouseService } from 'services/warehouse';
import {
  OrderItems,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import { formatDateWithUTCOffset } from 'utils/warehouse-management/format-date-with-utc-offset';
import { setHours, setMinutes } from 'date-fns';
import { areDatesEqual } from 'utils/warehouse-management/are-dates-equal';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { QUERY_KEY_ORDER } from 'services/warehouse/get-order';
import * as ReactQuery from 'react-query';
import SuccessMessage from 'features/warehouses/components/SuccessMessage/SuccessMessage';
import { getSelectedWarehouse } from 'utils/warehouse-management/get-selected-warehouse';
import { areItemsEqual } from 'utils/warehouse-management/are-items-equal';
import * as Icons from 'components/icons';
import { useNotifications } from 'components/notifications/notifications';
import * as Utils from 'utils';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { UsersService } from 'services/users';
import { Box, Container, Grid } from '../../components/layout';
import { Text } from '../../components/typography';
import { ShouldRender } from '../../components/ShouldRender';
import { CreateOrderItemCard } from './components/CreateOrderItemCard/CreateOrderItemCard';
import { OrderWorkerView } from './components/OrderWorkerView/OrderWorkerView';

export const CreateOrder = () => {
  const [removedOrderItemsId, setRemovedOrderItemsId] = React.useState<
    string[]
  >([]);

  // Items are used only in edit mode and are not stored in local storage.
  const [editOrderItems, setEditOrderItems] = React.useState<(OrderItems & { projectName?: string; projectId?: string })[]
    >([]);

  // Items are used in create mode and are stored in local storage.
  const [selectedOrderItems, setSelectedOrderItems] = useLocalStorage<(OrderItems & { projectName?: string; projectId?: string })[]
    >(LOCAL_STORAGE_KEYS.SELECTED_ORDER_ITEMS, []);

  const params = ReactRouter.useParams<{ id: string }>();
  const orderId = params.id;
  const orderItems = orderId ? editOrderItems : selectedOrderItems;
  const notifications = useNotifications();

  const [formData, setFormData] = useLocalStorage(
    LOCAL_STORAGE_KEYS.ORDER_FORM_DATA,
    {
      name: '',
      address: '',
      email: '',
      phoneNumber: '',
      workerId: '',
      dispatchDate: '',
      notes: '',
    },
  );

  const selectedWarehouse = getSelectedWarehouse();

  const { currentUser, isLoading } = Utils.Auth.useCurrentAuthenticatedUser();
  const { active } = UsersService.useGetUsers();

  const workers = active?.filter(
    (user) => user.role === InviteUsersRequestEmailsRoleEnum.User,
  );

  const { order, isLoading: isOrderLoading } = WarehouseService.useGetOrder(
    orderId ?? '',
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (orderId && data && data.items) {
          formMethods.reset({
            name: data.name || '',
            notes: data.notes || '',
            dispatchDate: data.arrivalDate || '',
            workerId: data.workerId || '',
            address: data.address || '',
            email: data.email || '',
            phoneNumber: data.phoneNumber || '',
          });
          setEditOrderItems(data.items.map((item) => ({ ...item })));
        }
      },
    },
  );

  const { warehouseItems, isLoading: areWarehouseItemsLoading } = WarehouseService.useGetWarehouseItems(
    order?.warehouseId || selectedWarehouse.id,
    {
      enabled:
          currentUser?.signInUserSession.idToken.payload.role
          !== InviteUsersRequestEmailsRoleEnum.User,
    },
  );

  const formSchema = Joi.object({
    name: ValidationSchemas.CreateOrder.ORDER_NAME,
    address: ValidationSchemas.CreateOrder.ADDRESS,
    email: ValidationSchemas.CreateOrder.EMAIL,
    notes: ValidationSchemas.CreateOrder.NOTES,
    phoneNumber: ValidationSchemas.CreateOrder.PHONE_NUMBER,
    workerId: ValidationSchemas.CreateOrder.WORKER,
    dispatchDate: ValidationSchemas.CreateOrder.DATE,
  });

  const formMethods = Rhf.useForm({
    defaultValues: orderId
      ? {
        name: order?.fullName || '',
        notes: order?.notes || '',
        dispatchDate: order?.arrivalDate || '',
        workerId: order?.workerId || '',
        address: order?.address || '',
        email: order?.email || '',
        phoneNumber: order?.phoneNumber || '',
      }
      : formData,
    resolver: joiResolver(formSchema),
  });

  const { isDirty, dirtyFields } = Rhf.useFormState({
    control: formMethods.control,
  });

  const initialDispatchDate = orderId
    ? order?.arrivalDate
    : formData?.dispatchDate;

  const convertedDispatchDate = React.useMemo(() => {
    return initialDispatchDate
      ? formatDateWithUTCOffset(new Date(initialDispatchDate))
      : null;
  }, [initialDispatchDate]);

  const [dispatchDate, setDispatchDate] = React.useState<Date | null>(
    convertedDispatchDate,
  );

  React.useEffect(() => {
    if (convertedDispatchDate) {
      setDispatchDate(convertedDispatchDate);
    }
  }, [convertedDispatchDate]);

  const isDateChanged = !areDatesEqual(
    order?.arrivalDate
      ? formatDateWithUTCOffset(new Date(order.arrivalDate))
      : '',
    dispatchDate,
  );

  const queryClient = ReactQuery.useQueryClient();

  const {
    createOrder,
    isLoading: isCreateOrderLoading,
    isSuccess: isCreateOrderSuccess,
    reset: resetCreateOrder,
  } = WarehouseService.useCreateOrder({
    onSuccess: async () => {
      setSelectedOrderItems([]);
      setDispatchDate(null);
      setFormData({
        name: '',
        notes: '',
        dispatchDate: '',
        workerId: '',
        address: '',
        email: '',
        phoneNumber: '',
      });
      formMethods.reset({
        name: '',
        notes: '',
        dispatchDate: '',
        workerId: '',
        address: '',
        email: '',
        phoneNumber: '',
      });

      setTimeout(() => {
        resetCreateOrder();
      }, 1500);
    },
  });

  const {
    updateOrder,
    reset,
    isSuccess: isUpdateOrderSuccess,
  } = WarehouseService.useUpdateOrder({
    onSuccess: async () => {
      if (payload.role === InviteUsersRequestEmailsRoleEnum.Admin) {
        approveOrder({ orderId });
      }
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ORDER(orderId)),
      ]);
      setTimeout(() => {
        reset();
      }, 1500);
    },
  });

  const {
    approveOrder,
    isSuccess: isApproveOrderSuccess,
    reset: resetApproveOrder,
  } = WarehouseService.useApproveOrder({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ORDER(orderId)),
      ]);
      setTimeout(() => {
        resetApproveOrder();
      }, 1500);
    },
  });

  const history = ReactRouter.useHistory();

  const generateOrderButtonText = () => {
    if (payload.role === InviteUsersRequestEmailsRoleEnum.Admin && orderId) {
      return <Text intlId="order.approveOrder" variant="button.text" />;
    }

    if (payload.role === InviteUsersRequestEmailsRoleEnum.Client && orderId) {
      return <Text intlId="order.editOrder" variant="button.text" />;
    }

    return <Text intlId="order.createOrder" variant="button.text" />;
  };

  const handleFormChange = (fieldName: string, value: any) => {
    if (!orderId) {
      setFormData((prevState) => {
        if (!prevState) {
          return formData;
        }
        return {
          ...prevState,
          [fieldName]: value,
        };
      });
    }
    return value;
  };

  const handleOrderItemsChange = (items: OrderItems[]) => {
    if (orderId) {
      setEditOrderItems(items);
    } else {
      setSelectedOrderItems(items);
    }
  };

  const mapSelectedUpdateOrderItems = (items: OrderItems[]) => {
    return items.map((item) => ({
      id: item.itemId,
      orderedQuantity: item.orderedQuantity,
      position: item.position || '',
    }));
  };

  const mapSelectedCreateOrderItems = (items: OrderItems[]) => {
    return items.map((item) => ({
      id: item.itemId,
      orderedQuantity: item.orderedQuantity,
      position: item.position || '',
    }));
  };

  const updateRemovedOrderItemsId = (itemId: string) => {
    setRemovedOrderItemsId((prevState = []) => [...prevState, itemId]);
  };

  const {
    rejectOrder,
    isLoading: isRejectOrderLoading,
    isSuccess: isRejectOrderSuccess,
    reset: resetRejectOrder,
  } = WarehouseService.useRejectOrder({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ORDER(orderId)),
      ]);
      setTimeout(() => {
        resetRejectOrder();
      }, 1500);
    },
  });

  if (isRejectOrderLoading || areWarehouseItemsLoading || isOrderLoading) {
    return <InlineLoadingIndicator />;
  }

  if (isRejectOrderSuccess) {
    return (
      <SuccessMessage
        successMessage="rejectOrder.success"
        animationAndTextColor="red"
      />
    );
  }

  const areItemsChanged = !areItemsEqual(
    editOrderItems || [],
    order?.items ? order.items : [],
  );

  if (isApproveOrderSuccess) {
    return <SuccessMessage successMessage="approveOrder.success" />;
  }

  if (!currentUser || isLoading) {
    return <PageLoadingIndicator />;
  }

  const {
    signInUserSession: {
      idToken: { payload },
    },
  } = currentUser;

  const isRoleAdmin = payload.role === InviteUsersRequestEmailsRoleEnum.Admin;
  const isRoleClient = payload.role === InviteUsersRequestEmailsRoleEnum.Client;
  const isRoleWorker = payload.role === InviteUsersRequestEmailsRoleEnum.User;

  return (
    <Container
      variant="container"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      {isRoleWorker
      || order?.status === 'APPROVED'
      || order?.status === 'REJECTED'
      || order?.status === 'DISPATCHED' ? (
        <OrderWorkerView order={order} role={payload.role} />
        ) : isCreateOrderSuccess || (isUpdateOrderSuccess && isRoleClient) ? (
          isCreateOrderSuccess ? (
            <SuccessMessage successMessage="createOrder.success" />
          ) : (
            <SuccessMessage successMessage="editOrder.success" />
          )
        ) : (
          <>
            {order?.status === 'SCHEDULED' && isRoleAdmin && (
            <Button
              prependIcon={<Icons.Close sx={{ stroke: 'error' }} width={20} />}
              variant="text"
              sx={{
                width: 'fit-content',
                alignSelf: 'flex-end',
                color: 'error',
                gap: 0,
              }}
              intlId="processOrder.rejectOrder.button"
              onClick={() => rejectOrder({ orderId })}
            />
            )}

            <Forms.Provider
              onValid={async ({
                name,
                address,
                dispatchDate,
                email,
                phoneNumber,
                notes,
                workerId,
              }) => {
                const updatedFields = {
                  ...(dirtyFields.name && { name }),
                  ...(dirtyFields.workerId && { workerId }),
                  ...(dirtyFields.address && { address }),
                  ...(dirtyFields.notes && { notes }),
                  ...(isDateChanged && { arrivalDate: dispatchDate }),
                  ...(dirtyFields.phoneNumber && { phoneNumber }),
                  ...(dirtyFields.email && { email }),
                };
                const updateItems = orderItems && mapSelectedUpdateOrderItems(orderItems);
                const projectIds = orderItems?.map((item) => item.projectId);

                // all items should have same projectId on create
                const projectId = projectIds && projectIds[0];

                const areAllProjectIdsSame = projectIds?.every((id) => id === projectId) ?? false;

                if (orderItems?.length === 0) {
                  notifications.error({
                    description: 'createOrder.noItems.error.message',
                    durationMs: 2000,
                  });
                  return;
                }

                if (orderId) {
                  if (isRoleClient) {
                    updateOrder({
                      orderID: orderId,
                      orderData: {
                        ...updatedFields,
                        items: updateItems || [],
                        ...(removedOrderItemsId.length > 0 && {
                          itemsToRemove: removedOrderItemsId,
                        }),
                      },
                    });
                  } else if (isRoleAdmin) {
                    if (
                      Object.keys(updatedFields).length !== 0
                    || areItemsChanged
                    ) {
                      updateOrder({
                        orderID: orderId,
                        orderData: {
                          ...updatedFields,
                          items: updateItems || [],
                          ...(removedOrderItemsId.length > 0 && {
                            itemsToRemove: removedOrderItemsId,
                          }),
                        },
                      });
                    } else {
                      approveOrder({ orderId });
                    }
                  }
                } else {
                  const createItems = orderItems && mapSelectedCreateOrderItems(orderItems);
                  if (areAllProjectIdsSame && projectId) {
                    createOrder({
                      name,
                      fullName: `${payload.given_name} ${payload.family_name}`,
                      address,
                      ...(dispatchDate && { arrivalDate: dispatchDate }),
                      email,
                      phoneNumber,
                      projectId,
                      notes,
                      ...(workerId && { workerId }),
                      items: createItems || [],
                      warehouseId: selectedWarehouse.id,
                    });
                  } else {
                    notifications.error({
                      description: 'createOrder.diffrentProject.error',
                      durationMs: 6000,
                    });
                  }
                }
              }}
              name="createOrder"
              {...formMethods}
            >
              {orderItems?.map((item) => (
                <CreateOrderItemCard
                  updateRemovedOrderItemsId={updateRemovedOrderItemsId}
                  warehouseItems={warehouseItems?.items}
                  item={item}
                  key={item.id}
                  handleSelectedOrderItemsChange={handleOrderItemsChange}
                  orderItems={orderItems}
                  projectName={orderId ? order?.projectName : item.projectName}
                />
              ))}
              <Grid columns={2}>
                <Forms.FieldEditText
                  labelIntlId="createOrder.form.name"
                  name="name"
                  onMapChange={(value) => handleFormChange('name', value)}
                  required
                />

                <Forms.FieldEditText
                  labelIntlId="createWarehouse.form.input.address.label"
                  name="address"
                  onMapChange={(value) => handleFormChange('address', value)}
                  required
                />

                <Forms.FieldEditText
                  labelIntlId="login.form.input.email.label"
                  name="email"
                  onMapChange={(value) => {
                    const trimmedValue = value.trim();
                    handleFormChange('email', trimmedValue);
                    return trimmedValue;
                  }}
                  required
                />

                <Forms.FieldEditText
                  onMapChange={(value) => {
                    const trimmedValue = value.trim();
                    handleFormChange('phoneNumber', trimmedValue);
                    return trimmedValue;
                  }}
                  labelIntlId="createAccount.form.input.phoneNumber.label"
                  name="phoneNumber"
                  required
                />

                <ShouldRender when={isRoleAdmin}>
                  <Forms.SelectField
                    isLabelAlwaysLifted
                    labelIntlId="delivery.addDelivery.worker"
                    name="workerId"
                    onMapChange={(event) => {
                      handleFormChange('workerId', event.target.value);
                    }}
                  >
                    <option
                      value="" selected
                      disabled hidden
                    >
                      Choose worker
                    </option>
                    {workers?.map((worker) => (
                      <option
                        value={worker.userId}
                        key={`assigned-to-select.option__${worker.userId}`}
                      >
                        {worker.user.firstName} {worker.user.lastName}
                      </option>
                    ))}
                  </Forms.SelectField>
                </ShouldRender>
              </Grid>
              <Forms.FieldEditText
                labelIntlId="order.addOrder.orderNotes"
                name="notes"
                required
                onMapChange={(value) => handleFormChange('notes', value)}
              />
              <Grid columns={2}>
                <Text intlId="createDelivery.form.deliveryDate.label" />
                <DatePicker
                  startDate={dispatchDate}
                  setStartDate={(date) => {
                    if (date) {
                      const localDate = new Date(
                        date.getTime() - date.getTimezoneOffset() * 60000,
                      );

                      setDispatchDate(date);
                      handleFormChange('dispatchDate', localDate.toISOString());
                      formMethods.setValue(
                        'dispatchDate',
                        localDate.toISOString(),
                      );
                    }
                  }}
                  showTimeSelect
                  minTime={setHours(setMinutes(new Date(), 0), 8)}
                  maxTime={setHours(setMinutes(new Date(), 0), 17)}
                />
                <Box />
              </Grid>
              <Grid columns={2}>
                <Button variant="preview" onClick={() => history.goBack()}>
                  <Text intlId="generic.button.goBack" />
                </Button>

                <Forms.SubmitButton
                  isLoading={isCreateOrderLoading}
                  sx={{ height: 'auto' }}
                  disabled={
                  !!(
                    orderId
                    && !isRoleAdmin
                    && !isDirty
                    && !isDateChanged
                    && !areItemsChanged
                  )
                }
                >
                  {generateOrderButtonText()}
                </Forms.SubmitButton>
              </Grid>
            </Forms.Provider>
          </>
        )}
    </Container>
  );
};
