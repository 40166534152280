import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getAllWarehousesApi } from './api';

export const QUERY_KEY_ALL_WAREHOUSES = ['allWarehouses'];

export function useGetAllWarehouses(
  options?: InferQueryOptions<typeof getAllWarehousesApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ALL_WAREHOUSES,
    getAllWarehousesApi,
    options,
  );

  return {
    warehouses: data?.warehouses,
    totalWarehouses: data?.total ?? 0,
    ...rest,
  };
}
