import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from 'utils/local-storage';
import { getSelectedWarehouse } from 'utils/warehouse-management/get-selected-warehouse';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import * as Utils from 'utils';
import { InviteUsersRequestEmailsRoleEnum } from 'trace-backend-sdk';
import { Box, Container, Flex } from '../../components/layout';
import { Text } from '../../components/typography';
import { Button } from '../../components/form-elements';
import {
  WarehouseOverviewTabOptions,
  WarehouseTab,
} from '../../features/warehouses/types/warehousesTypes';
import { WarehouseTabs } from '../../features/warehouses/components/WarehouseTabs/WarehouseTabs';
import { ShouldRender } from '../../components/ShouldRender';
import { WarehouseItems } from './components/WarehouseItems/WarehouseItems';
import { WarehouseOrders } from './components/WarehouseOrders/WarehouseOrders';
import { WarehouseDeliveries } from './components/WarehouseDeliveries/WarehouseDeliveries';

export type WarehouseInventoryItemType = {
  name: string;
  id: string;
  projectName: string;
  position: string;
  stock: number;
  quantity?: number;
};

export const WarehouseOverview: React.FC = () => {
  const history = ReactRouter.useHistory();

  const selectedWarehouse = getSelectedWarehouse();

  const { currentUser, isLoading } = Utils.Auth.useCurrentAuthenticatedUser();

  const storedDeliveryItems = localStorage.getItem(
    LOCAL_STORAGE_KEYS.SELECTED_DELIVERY_ITEMS,
  );

  const selectedDeliveryItems = storedDeliveryItems && JSON.parse(storedDeliveryItems);

  const [selectedOrderItems, setSelectedOrderItems] = useLocalStorage<
    WarehouseInventoryItemType[]
  >(LOCAL_STORAGE_KEYS.SELECTED_ORDER_ITEMS, []);

  if (!currentUser || isLoading) {
    return <PageLoadingIndicator />;
  }

  const {
    signInUserSession: {
      idToken: { payload },
    },
  } = currentUser;

  const handleOrderClick = () => {
    if (selectedOrderItems?.length !== 0) {
      history.push('/new-order');
    }
  };

  const handleDeliveryClick = () => {
    history.push('/new-delivery');
  };

  const handleSelectedOrderItemsChange = (item: WarehouseInventoryItemType) => {
    if (selectedOrderItems) {
      const updatedActiveOrderyGlobal = [...selectedOrderItems, item];
      setSelectedOrderItems(updatedActiveOrderyGlobal);
    }
  };

  const WarehouseItemsTab: WarehouseTab = {
    name: WarehouseOverviewTabOptions.Items,
    component: (
      <WarehouseItems
        role={payload.role}
        warehouseId={selectedWarehouse.id}
        selectedOrderItems={selectedOrderItems}
        handleSelectedOrderItemsChange={handleSelectedOrderItemsChange}
      />
    ),
  };

  const WarehouseOrderTab: WarehouseTab = {
    name: WarehouseOverviewTabOptions.Orders,
    component: <WarehouseOrders role={payload.role} />,
  };

  const WarehouseDeliveryTab: WarehouseTab = {
    name: WarehouseOverviewTabOptions.Deliveries,
    component: <WarehouseDeliveries role={payload.role} />,
  };

  const warehouseTabs: WarehouseTab[] = [
    WarehouseItemsTab,
    WarehouseOrderTab,
    WarehouseDeliveryTab,
  ];

  return (
    <Container variant="fullWidth">
      <Flex justifyContent="space-between" alignItems="center">
        <Text>
          <Text sx={{ fontWeight: 'bold' }}>{selectedWarehouse?.name}</Text>
        </Text>

        <Flex sx={{ width: 'auto', gap: 4 }}>
          <ShouldRender
            when={payload.role === InviteUsersRequestEmailsRoleEnum.Admin}
          >
            <Button
              disabled={selectedOrderItems?.length === 0}
              variant="primary"
              sx={{
                width: '180px',
                fontSize: 'md',
                backgroundColor:
                  selectedOrderItems?.length === 0
                    ? 'grey.300'
                    : 'tertiary1.300',
              }}
              onClick={handleOrderClick}
            >
              <Text intlId="order.newOrder" /> [{selectedOrderItems?.length}]
            </Button>
          </ShouldRender>

          <ShouldRender
            when={payload.role === InviteUsersRequestEmailsRoleEnum.Admin}
          >
            <Button
              variant="primary"
              sx={{
                width: '180px',
                fontSize: 'md',
              }}
              onClick={handleDeliveryClick}
            >
              <Text intlId="delivery.newDelivery" /> [
              {selectedDeliveryItems?.length || 0}]
            </Button>
          </ShouldRender>

          <ShouldRender
            when={payload.role === InviteUsersRequestEmailsRoleEnum.Client}
          >
            <Button
              disabled={selectedOrderItems?.length === 0}
              variant="primary"
              sx={{
                width: '180px',
                fontSize: 'md',
                backgroundColor:
                  selectedOrderItems?.length === 0
                    ? 'grey.300'
                    : 'tertiary1.300',
              }}
              onClick={handleOrderClick}
            >
              <Text intlId="order.newOrder" /> [{selectedOrderItems?.length}]
            </Button>
          </ShouldRender>
        </Flex>
      </Flex>

      <Box sx={{ mt: '30px' }}>
        <WarehouseTabs tabs={warehouseTabs} role={payload.role} />
      </Box>
    </Container>
  );
};
