import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { WarehouseTabCard } from 'features/warehouses/components/WarehouseTabCard/WarehouseTabCard';
import { Order } from 'trace-backend-sdk';
import { WarehouseService } from 'services/warehouse';
import { DocumentsService } from 'services/documents';
import axios from 'axios';
import { downloadFile } from 'utils/functions/download-file';
import { Text } from '../../../../../../components/typography';

type WarehouseOrdersCardProps = {
  order: Order;
  role: string;
};

export const WarehouseOrdersCard = ({
  order,
  role,
}: WarehouseOrdersCardProps) => {
  const history = ReactRouter.useHistory();
  const { deleteOrder } = WarehouseService.useDeleteOrder();
  const [isFetchEnabled, setIsFetchEnabled] = React.useState(false);

  const { orderDocumentUrl, isLoading } = DocumentsService.useGetOrderDocument(
    order.id,
    {
      refetchOnWindowFocus: false,
      enabled: isFetchEnabled,
    },
  );

  const { id } = order;
  const orderName = order.name || '';

  React.useEffect(() => {
    const downloadDocument = async () => {
      if (orderDocumentUrl) {
        const { data: pdfBlob } = await axios.get(orderDocumentUrl, {
          responseType: 'blob',
        });
        downloadFile(pdfBlob, orderName);
      }
    };

    if (orderDocumentUrl && isFetchEnabled) {
      downloadDocument();
      setIsFetchEnabled(false);
    }
  }, [orderDocumentUrl, orderName, isFetchEnabled]);

  const handleDownloadClick = () => {
    setIsFetchEnabled(true);
  };

  const handleOrderDetailsClick = () => {
    if (id) {
      history.push(`/order/${id}`);
    }
  };

  const handleRemoveOrder = () => {
    const text = 'Are you sure you want to remove this Order?';

    // eslint-disable-next-line no-alert
    if (window.confirm(text)) {
      deleteOrder({ id });
    }
  };

  return (
    <WarehouseTabCard
      handleDownloadPdfClick={handleDownloadClick}
      isDownloadLoading={isLoading}
      role={role}
      name={order.name}
      project={order.projectName}
      client={order.clientName}
      itemsNumber={order.items?.length}
      status={order.status}
      date={order.arrivalDate}
      handleRemoveEvent={handleRemoveOrder}
      handleEventDetailsClick={handleOrderDetailsClick}
      buttonTitle={
        <Text intlId="tab.orders.card.button" variant="button.text" />
      }
    />
  );
};
