import * as _ from 'lodash-es';
import * as Utils from 'utils';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { useParams } from 'react-router-dom';
import { DocumentTemplateId } from 'services/documents';

export const createRoutePath = (...paths: (string | number)[]) =>
  _.join(paths, '/');

const HOME = {
  getPath: () => createRoutePath('/'),
};

const SUCCESS = {
  getPath: () => createRoutePath('/success'),
};

const DEMO = {
  getPath: () => createRoutePath('/demo'),
};

const LOGIN = {
  getPath: () => createRoutePath('/login'),
};

const ADMIN = {
  Default: '/admin',
  Links: {
    Workspace: ({ workspaceId }: { workspaceId: string }) =>
      createRoutePath('workspaces', 'edit', workspaceId),
  },
  useWorkspaceParams: () => useParams<{ workspaceId: string }>(),
};

const PRESSURE = {
  getPath: () => createRoutePath('/pressure'),
  Edit: '/pressure/:id',
  Running: '/pressure-running',
  getEditPath: (id: string) => `/pressure/${id}`,
};

const REGISTER = {
  getPath: () => createRoutePath('/register'),
  getRegisterInvitedPath: () => createRoutePath('/register-invited'),
};

const CREATE_ORGANISATION = {
  getPath: () => createRoutePath('/create-organisation'),
};

const usersDefaultPath = '/users';
const USERS = {
  default: '/users',
  getPath: () => `${usersDefaultPath}/:status`,
  InviteUsers: {
    path: '/invite-users',
  },
};

type DocumentsLink = {
  project?: string;
  status?: DocumentStatusEnum;
};

export const DOCUMENTS_DEFAULT_PROJECT = 'all';
const DOCUMENTS_BASE_PATH = '/documents';
const DOCUMENTS = {
  default: DOCUMENTS_BASE_PATH,
  DefaultProject: DOCUMENTS_DEFAULT_PROJECT,
  Params: {
    project: ':project',
  },
  Links: {
    DocumentsList: ({
      project = DOCUMENTS_DEFAULT_PROJECT,
      status = DocumentStatusEnum.Draft,
    }: DocumentsLink) =>
      `${DOCUMENTS_BASE_PATH}/${project}/${status.toLowerCase()}`,
  },
} as const;

const SIGN_OUT = {
  getPath: () => createRoutePath('/sign-out'),
};

export type DocumentTab = 'main' | 'details' | 'recipients';
export type DocumentTabLink = {
  templateId: DocumentTemplateId;
  documentId: number;
  tab?: DocumentTab;
};

const DOCUMENT_VIEW_BASE_PATH = '/view-document';
const DOCUMENT = {
  default: DOCUMENT_VIEW_BASE_PATH,
  Create: {
    path: '/document-create/:templateId',
    useCreateParams: () => useParams<{ templateId: DocumentTemplateId }>(),
  },
  Links: {
    DocumentTab: ({ templateId, documentId }: DocumentTabLink) =>
      `${DOCUMENT_VIEW_BASE_PATH}/${templateId.toLowerCase()}/${documentId}`,
    DocumentCreate: ({ templateId }: { templateId: DocumentTemplateId }) =>
      `/document-create/${templateId}`,
  },
  Params: {
    templateId: ':templateId',
    documentId: ':documentId',
    tab: ':tab',
  },
} as const;

const SETTINGS = {
  getPath: () => createRoutePath('/settings'),
};

const PROFILE = {
  default: '/profile',
  Edit: '/profile/edit',
  View: '/profile/view',
} as const;

const transitionDefaultPath = '/info';
const TRANSITIONS = {
  default: transitionDefaultPath,
  getCreateWorkspaceSuccessPath: () => `${transitionDefaultPath}/congrats`,
  getPasswordInstructionsPath: () => `${transitionDefaultPath}/send-email`,
  SuccessConfirmation: `${transitionDefaultPath}/success-confirmation`,
};

const SEARCH = {
  Default: '/search',
};

const FORGOT_PASSWORD = {
  getPath: () => createRoutePath('/forgot-password'),
};

const CONFIRM_PASSWORD = {
  getPath: () => createRoutePath('/confirm-password'),
};

const DOWNLOAD_CERTIFICATE = {
  getPath: () => createRoutePath('/certificate'),
};

const VIEW_PDF = {
  default: '/view-pdf',
  Links: {
    ViewPdf: ({ pdfUrl }: { pdfUrl: string }) =>
      [
        '/view-pdf',
        Utils.Url.composeSearchQuery({
          pdfUrl,
        }),
      ].join('?'),
  },
} as const;

const MESSAGES = {
  Default: '/messages',
} as const;

const PERMISSIONS = {
  PerTemplate: {
    path: '/user-permissions/:templateId',
    useParams: () => useParams<{ templateId: DocumentTemplateId }>(),
    getLink: ({ templateId }: { templateId: DocumentTemplateId }) =>
      `/user-permissions/${templateId}`,
  },
  Default: {
    path: '/user-permissions',
  },
};

const PAYMENTS = {
  Billing: {
    path: '/billing',
    DiscountSchedule: {
      path: '/billing/discount-schedule',
    },
  },
  ManagePaymentInfo: {
    path: '/manage-payment-info',
  },
  ManageSubscription: {
    path: '/manage-subscription',
  },
  PaymentHistory: {
    path: '/payment-history',
  },
  UpcomingPayment: {
    path: '/upcoming-payment',
  },
};

const WAREHOUSES = {
  getPath: () => createRoutePath('/warehouses'),
};

const PROJECTS = {
  getPath: () => createRoutePath('/projects'),
};

const CALENDAR = {
  getPath: () => createRoutePath('/calendar'),
};

const WAREHOUSES_OVERVIEW_BASE_PATH = '/warehouse';
const WAREHOUSES_OVERVIEW = {
  default: WAREHOUSES_OVERVIEW_BASE_PATH,
  Params: { warehouseId: ':warehouseId' },
};

const ADD_ITEM = {
  getPath: () => createRoutePath('/new-item'),
};

const ITEM_BASE_PATH = '/item';
const ITEM = {
  default: ITEM_BASE_PATH,
  Params: { id: ':id' },
};

const CREATE_ORDER = {
  getPath: () => createRoutePath('/new-order'),
};

const ORDER_BASE_PATH = '/order';
const ORDER = {
  default: ORDER_BASE_PATH,
  Params: { id: ':id' },
};

const DISPATCH_BASE_PATH = '/dispatch';
const DISPATCH = {
  default: DISPATCH_BASE_PATH,
  Params: { id: ':id' },
};

const DELIVERY_BASE_PATH = '/delivery';
const DELIVERY = {
  default: DELIVERY_BASE_PATH,
  Params: { id: ':id' },
};

const CREATE_DISPATCH = {
  getPath: () => createRoutePath('/create-dispatch'),
};

const CREATE_DELIVERY = {
  getPath: () => createRoutePath('/new-delivery'),
};

export const Routing = {
  ADMIN,
  CONFIRM_PASSWORD,
  CREATE_ORGANISATION,
  DOWNLOAD_CERTIFICATE,
  DEMO,
  DOCUMENT,
  DOCUMENTS,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  PRESSURE,
  PROFILE,
  REGISTER,
  SEARCH,
  SETTINGS,
  SIGN_OUT,
  SUCCESS,
  TRANSITIONS,
  USERS,
  VIEW_PDF,
  MESSAGES,
  PERMISSIONS,
  PAYMENTS,
  WAREHOUSES,
  PROJECTS,
  CALENDAR,
  WAREHOUSES_OVERVIEW,
  ADD_ITEM,
  ITEM,
  CREATE_ORDER,
  CREATE_DISPATCH,
  CREATE_DELIVERY,
  ORDER,
  DISPATCH,
  DELIVERY,
};
