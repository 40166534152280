import { DocumentDetail } from 'pages/Document/components/DocumentEdit/components/DocumentDetails';
import * as Icons from 'components/icons';
import {
  FinalizeDeliveryRequestItems,
  GetDeliveryResponse,
} from 'trace-backend-sdk';
import { Button, Forms } from 'components/form-elements';
import * as Rhf from 'react-hook-form';
import { ValidationSchemas } from 'utils/validation';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Grid } from 'components/layout';
import * as ReactRouter from 'react-router-dom';
import { WarehouseService } from 'services/warehouse';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import React from 'react';
import SuccessMessage from 'features/warehouses/components/SuccessMessage/SuccessMessage';
import { QUERY_KEY_DELIVERY } from 'services/warehouse/get-delivery';
import * as ReactQuery from 'react-query';
import { Text } from '../../../../components/typography';
import { DeliveryWorkerItemCard } from '../DeliveryWorkerItemCard/DeliveryWorkerItemCard';

type DeliveryWorkerViewProps = {
  delivery?: GetDeliveryResponse;
};

const buildFinalizeDeliveryRequestItems = (
  delivery: GetDeliveryResponse,
  deliveryItems: {
    deliveredQuantity: number;
    position: string;
    width: number;
    height: number;
    length: number;
  }[],
): FinalizeDeliveryRequestItems[] | undefined => {
  const finalizedItems = delivery?.items.map((item, index) => {
    const formItem = deliveryItems[index];

    return {
      id: item.itemId,
      deliveredQuantity: formItem.deliveredQuantity,
      position: formItem.position,
      width: formItem.width,
      height: formItem.height,
      length: formItem.length,
      floor: item.floor ?? 0,
      zone: item.zone ?? '',
      ...(item.unit && { unit: item.unit }),
    };
  });
  return finalizedItems;
};

export const DeliveryWorkerView = ({ delivery }: DeliveryWorkerViewProps) => {
  const history = ReactRouter.useHistory();
  const queryClient = ReactQuery.useQueryClient();
  const isDeliveryRejected = delivery?.status === 'REJECTED';
  const isDeliveryDelivered = delivery?.status === 'DELIVERED';

  const {
    rejectDelivery,
    isLoading: isRejectDeliveryLoading,
    isSuccess: isRejectDeliverySuccess,
    reset,
  } = WarehouseService.useRejectDelivery({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_DELIVERY(deliveryId)),
      ]);
      setTimeout(() => {
        reset();
      }, 1500);
    },
  });

  const {
    finalizeDelivery,
    isSuccess: isFinalizeDeliverySuccess,
    reset: resetFinalizeDelivery,
  } = WarehouseService.useFinalizeDelivery({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_DELIVERY(deliveryId)),
      ]);
      setTimeout(() => {
        resetFinalizeDelivery();
      }, 1500);
    },
  });

  const params = ReactRouter.useParams<{ id: string }>();

  const deliveryId = params.id;
  const newDate = new Date(delivery?.deliveryDate || '');
  const formattedDate = delivery?.deliveryDate
    && newDate.toISOString().slice(0, 19).replace('T', ' ').slice(0, -3);

  const splittedNotes = delivery?.notes && delivery.notes.split(',');

  const adminNotes = splittedNotes && splittedNotes[0];
  const workerNotes = splittedNotes && splittedNotes[1];

  const formSchema = Joi.object({
    deliveryItems: Joi.array().items(
      Joi.object({
        deliveredQuantity: ValidationSchemas.ProcessDelivery.DELIVERED_QUANTITY,
        position: ValidationSchemas.ProcessDelivery.POSITION,
        height: ValidationSchemas.ProcessDelivery.HEIGHT,
        width: ValidationSchemas.ProcessDelivery.WIDTH,
        length: ValidationSchemas.ProcessDelivery.LENGTH,
      }),
    ),
    notes: ValidationSchemas.ProcessDelivery.NOTES,
  });

  const defaultValues = {
    deliveryItems: delivery?.items.map((item) => ({
      deliveredQuantity: 0,
      position: '',
      height: item.height || 0,
      width: item.width || 0,
      length: item.length || 0,
    })),
    notes: '',
  };

  const formMethods = Rhf.useForm({
    defaultValues,
    resolver: joiResolver(formSchema),
  });

  if (isRejectDeliveryLoading) {
    return <InlineLoadingIndicator />;
  }

  if (isRejectDeliverySuccess) {
    return (
      <SuccessMessage
        successMessage="rejectDelivery.success"
        animationAndTextColor="red"
      />
    );
  }

  if (isFinalizeDeliverySuccess) {
    return <SuccessMessage successMessage="finalizeDelivery.success" />;
  }

  return (
    <Forms.Provider
      onValid={(data) => {
        if (data?.deliveryItems && delivery) {
          const finalizedItems = buildFinalizeDeliveryRequestItems(
            delivery,
            data.deliveryItems,
          );

          finalizeDelivery({
            deliveryId,
            finalizedData: {
              ...(data.notes && {
                notes: delivery.notes
                  ? `${delivery.notes}, Worker notes: ${data.notes}`
                  : `Worker notes: ${data.notes}`,
              }),
              items: finalizedItems || [],
            },
          });
        }
      }}
      name="finishDelivery"
      {...formMethods}
    >
      {isDeliveryRejected || isDeliveryDelivered ? (
        <Text
          sx={{
            width: 'fit-content',
            alignSelf: 'flex-end',
            color: isDeliveryRejected ? 'error' : 'tertiary1.300',
            fontWeight: 500,
          }}
        >
          {isDeliveryRejected ? 'REJECTED' : 'DELIVERED'}
        </Text>
      ) : (
        <Button
          onClick={() => rejectDelivery({ deliveryId })}
          prependIcon={<Icons.Close sx={{ stroke: 'error' }} width={20} />}
          variant="text"
          sx={{
            width: 'fit-content',
            alignSelf: 'flex-end',
            color: 'error',
            gap: 0,
          }}
        >
          <Text intlId="processDelivery.rejectDelivery.button" />
        </Button>
      )}
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.deliveryName"
        icon={<Icons.FileMinus width="35px" />}
      >
        <Text>{delivery?.name}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.project"
        icon={<Icons.Building width="35px" />}
      >
        <Text>{delivery?.projectName}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.worker"
        icon={<Icons.Assignee width="35px" />}
      >
        <Text>{delivery?.workerName}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="createDelivery.form.deliveryDate.label"
        icon={<Icons.Created width="35px" />}
      >
        <Text>{formattedDate}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.deliveryNotes"
        icon={<Icons.CreatedBy width="35px" />}
      >
        <Text>
          {adminNotes}
          <br />
          {workerNotes}
        </Text>
      </DocumentDetail>
      {delivery?.items?.map((deliveryItem, index) => (
        <DeliveryWorkerItemCard
          status={delivery.status}
          width={deliveryItem.width}
          length={deliveryItem.length}
          deliveredQuantity={deliveryItem.deliveredQuantity}
          position={deliveryItem.position}
          imageUrl={deliveryItem.image}
          deliveredQuantityName={`deliveryItems[${index}].deliveredQuantity`}
          heightName={`deliveryItems[${index}].height`}
          widthName={`deliveryItems[${index}].width`}
          lengthName={`deliveryItems[${index}].length`}
          positionName={`deliveryItems[${index}].position`}
          name={deliveryItem.name}
          category={deliveryItem.category}
          project={delivery.projectName}
          plannedQuantity={deliveryItem.plannedQuantity}
        />
      ))}
      {delivery?.status === 'SCHEDULED' && (
        <Forms.FieldEditText
          labelIntlId="processDelivery.form.input.workerNotes.label"
          name="notes"
          disabled={isDeliveryRejected || isDeliveryDelivered}
        />
      )}
      <Grid
        columns={!isDeliveryRejected && !isDeliveryDelivered ? 2 : 1}
        sx={{ justifyItems: 'center' }}
      >
        <Button
          sx={{ maxWidth: '490px' }}
          variant="preview"
          onClick={() => history.goBack()}
          intlId="generic.button.goBack"
        />
        {!isDeliveryRejected && !isDeliveryDelivered && (
          <Forms.SubmitButton sx={{ height: 'auto' }}>
            <Text intlId="processDelivery.finishDelivery.button" />
          </Forms.SubmitButton>
        )}
      </Grid>
    </Forms.Provider>
  );
};
