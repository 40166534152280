import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { finalizeDeliveryApi } from './api';

export function useFinalizeDelivery(
  options?: InferMutationOptions<typeof finalizeDeliveryApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'finalize-delivery',
    finalizeDeliveryApi,
    options,
  );

  return {
    finalizeDelivery: mutate,
    ...rest,
  };
}
