import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { QUERY_KEY_ALL_ORDERS } from '../get-all-orders';
import { deleteOrderApi } from './api';

export function useDeleteOrder(
  options?: InferMutationOptions<typeof deleteOrderApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  const { mutate: deleteOrder, ...rest } = ReactQuery.useMutation(
    ['deleteOrderApi'],
    deleteOrderApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QUERY_KEY_ALL_ORDERS);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return { deleteOrder, ...rest };
}
