import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { createItemApi } from './api';

export function useCreateItem(
  options?: InferMutationOptions<typeof createItemApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'createItem',
    createItemApi,
    options,
  );

  return {
    createItem: mutate,
    ...rest,
  };
}
