import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { createDeliveryApi } from './api';

export function useCreateDelivery(
  options?: InferMutationOptions<typeof createDeliveryApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'createDelivery',
    createDeliveryApi,
    options,
  );

  return {
    createDelivery: mutate,
    ...rest,
  };
}
