import * as React from 'react';
import { WarehouseService } from 'services/warehouse';
import { Delivery } from 'trace-backend-sdk';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { Text } from 'components/typography';
import { Box, Container } from '../../../../components/layout';
import { WarehouseDeliveriesCard } from './components/WarehouseDeliveriesCard/WarehouseDeliveriesCard';

type WarehouseDeliveriesProps = {
  role: string;
};

export const WarehouseDeliveries = ({ role }: WarehouseDeliveriesProps) => {
  const { allDeliveries, isLoading } = WarehouseService.useGetAllDeliveries();

  if (isLoading) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Container
      variant="container"
      sx={{
        p: 0,
      }}
    >
      {allDeliveries && allDeliveries?.length > 0 ? (
        <Box>
          {allDeliveries?.map((delivery: Delivery) => {
            return (
              <WarehouseDeliveriesCard
                role={role}
                delivery={delivery}
                key={`${delivery.id}`}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            intlId="tab.deliveries.noDeliveries.message"
            sx={{ textAlign: 'center', fontStyle: 'italic', width: 'full' }}
          />
        </Box>
      )}
    </Container>
  );
};
