import * as React from 'react';
import { UsersService } from 'services/users';
import { Box, Flex } from '../../../../components/layout';
import { Image } from '../../../../components/Image';
import { Text } from '../../../../components/typography';
import { Button } from '../../../../components/workspace-layout/components';
import * as Icons from '../../../../components/icons';
import { RemoveButton } from '../RemoveButton/RemoveButton';
import placeholderImage from '../../assets/placeholderWarehouseImage.jpg';

type CreateEventItemCardProps = {
  name?: string;
  project?: string;
  stock?: number;
  imageUrl?: string;
  category?: string;
  plannedQuantity?: number;
  height?: number;
  width?: number;
  length?: number;
  handleMinusButtonClick?: () => void;
  handleItemQuantityChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleItemQuantityBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePlusButtonClick?: () => void;
  handleRemoveItem?: () => void;
  updateActiveDeliveryItem?: (
    field: 'plannedQuantity' | 'width' | 'height' | 'length',
    value: number | string
  ) => void;
};

export const CreateEventItemCard = ({
  name,
  project,
  category,
  imageUrl,
  stock,
  plannedQuantity,
  height,
  width,
  length,
  handleMinusButtonClick,
  handleItemQuantityChange,
  handlePlusButtonClick,
  handleRemoveItem,
  handleItemQuantityBlur,
  updateActiveDeliveryItem,
}: CreateEventItemCardProps) => {
  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(imageUrl);

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: 3,
        borderRadius: 2,
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Flex
          sx={{
            width: stock ? '150px' : '180px',
            height: stock ? '90px' : '120px',
          }}
        >
          <Image
            src={shouldShowFallback ? placeholderImage : image}
            alt={name}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 1,
            }}
          />
        </Flex>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Text
            sx={{
              fontSize: 24,
              fontWeight: 500,
              lineHeight: '20px',
            }}
          >
            {name}
          </Text>

          <Text
            variant="userCardTitle"
            sx={{ fontWeight: 500, fontSize: 'md' }}
          >
            {project}
          </Text>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 8,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignSelf: 'center',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: stock ? 'column' : 'row',
                gap: 2,
              }}
            >
              {stock ? <Text>IN STOCK: {stock}</Text> : <Text>Quantity:</Text>}
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={handleMinusButtonClick}
                  prependIcon={<Icons.Remove />}
                  variant="secondary"
                  sx={{
                    height: '30px',
                    width: '48px',
                    flexShrink: 0,
                  }}
                />
                <input
                  name="quantity"
                  type="number"
                  min={1}
                  inputMode="numeric"
                  value={plannedQuantity}
                  onChange={handleItemQuantityChange}
                  onBlur={handleItemQuantityBlur}
                  style={{
                    width: '120px',
                    height: '30px',
                  }}
                />
                <Button
                  onClick={handlePlusButtonClick}
                  prependIcon={<Icons.Add />}
                  variant="primary"
                  sx={{
                    height: '30px',
                    width: '48px',
                    flexShrink: 0,
                  }}
                />
              </Box>
            </Box>
            {stock ? null : (
              <Flex gap={2}>
                <Flex sx={{ gap: 2, alignItems: 'center' }}>
                  <Text>Height:</Text>
                  <input
                    name="height"
                    type="number"
                    value={height}
                    onChange={(e) =>
                      updateActiveDeliveryItem
                      && updateActiveDeliveryItem('height', e.target.valueAsNumber)}
                    style={{
                      width: '60px',
                      height: '30px',
                    }}
                  />
                </Flex>
                <Flex sx={{ gap: 2, alignItems: 'center' }}>
                  <Text>Width:</Text>
                  <input
                    name="width"
                    type="number"
                    value={width}
                    onChange={(e) =>
                      updateActiveDeliveryItem
                      && updateActiveDeliveryItem('width', e.target.valueAsNumber)}
                    style={{
                      width: '60px',
                      height: '30px',
                    }}
                  />
                </Flex>
                <Flex sx={{ gap: 2, alignItems: 'center' }}>
                  <Text>Length:</Text>
                  <input
                    name="length"
                    type="number"
                    value={length}
                    onChange={(e) =>
                      updateActiveDeliveryItem
                      && updateActiveDeliveryItem('length', e.target.valueAsNumber)}
                    style={{
                      width: '60px',
                      height: '30px',
                    }}
                  />
                </Flex>
              </Flex>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            height: 'inherit',
          }}
        >
          <Box>
            <RemoveButton onClick={handleRemoveItem} />
          </Box>
          <Text
            variant="cardLabelSmall"
            sx={{ lineHeight: '16px', mt: 'auto', width: 'fit-content' }}
          >
            {category || 'No category'}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};
