import Joi from 'joi';

export const DELIVERY_NAME = Joi.string().required().messages({
  'string.empty': 'createDelivery.form.input.deliveryName.error.required',
  'any.required': 'createDelivery.form.input.deliveryName.error.required',
});

export const PROJECT = Joi.string().required().messages({
  'string.empty': 'createDelivery.form.input.project.error.required',
  'any.required': 'createDelivery.form.input.project.error.required',
});

export const GATE = Joi.string().required().messages({
  'string.empty': 'createDelivery.form.input.gate.error.required',
  'any.required': 'createDelivery.form.input.gate.error.required',
});

export const TRANSPORTATION_TYPE = Joi.string().required().messages({
  'string.empty': 'createDelivery.form.input.transportationType.error.required',
  'any.required': 'createDelivery.form.input.transportationType.error.required',
});

export const NOTES = Joi.any();

export const WORKER = Joi.any();

export const DATE = Joi.any();
