import React from 'react';
import { IntlProps, LocaleKey } from 'translations';
import { Text } from 'components/typography/Text';
import { Flex } from 'components/layout';

export type LabelProps = Omit<IntlProps, 'intlId'> & {
  itemNumber: number;
  intlId: LocaleKey;
};

export const Label: React.FC<LabelProps> = ({ itemNumber, intlId }) => (
  <Flex>
    <Text
      variant="cardLabelSmall"
      sx={{
        mr: '1',
      }}
    >
      {itemNumber}
    </Text>
    <Text variant="cardLabelSmall" intlId={intlId} />
  </Flex>
);
