import { useCreateItem } from './create-item';
import { useCreateWarehouse } from './create-warehouse';
import { useGetAllItems } from './get-all-items';
import { useGetAllWarehouses } from './get-all-warehouses';
import { useUploadItemImage } from './upload-item-image';
import { useGetClientProjects } from './get-client-projects';
import { useUploadWarehouseImage } from './upload-warehouse-image';
import { useGetWorkspaceClients } from './get-workspace-clients';
import { useCreateDelivery } from './create-delivery';
import { useGetAllDeliveries } from './get-all-deliveries';
import { useGetDelivery } from './get-delivery';
import { useDeleteDelivery } from './delete-delivery/hook';
import { useUpdateDelivery } from './update-delivery';
import { useRejectDelivery } from './reject-delivery/hook';
import { useFinalizeDelivery } from './finalize-delivery';
import { useCreateOrder } from './create-order';
import { useGetAllOrders } from './get-all-orders';
import { useGetOrder } from './get-order';
import { useRejectOrder } from './reject-order';
import { useGetWarehouseItems } from './get-warehouse-items';
import { useUpdateOrder } from './update-order';
import { useDeleteOrder } from './delete-order';
import { useApproveOrder } from './approve-order';
import { useFinalizeOrder } from './finalize-order';
import { useGetAllProjects } from './get-all-projects';

export const WarehouseService = {
  useCreateWarehouse,
  useGetAllWarehouses,
  useUploadWarehouseImage,
  useCreateItem,
  useGetAllItems,
  useUploadItemImage,
  useGetClientProjects,
  useGetWorkspaceClients,
  useCreateDelivery,
  useGetAllDeliveries,
  useGetDelivery,
  useDeleteDelivery,
  useUpdateDelivery,
  useRejectDelivery,
  useFinalizeDelivery,
  useCreateOrder,
  useGetAllOrders,
  useGetOrder,
  useRejectOrder,
  useGetWarehouseItems,
  useUpdateOrder,
  useDeleteOrder,
  useApproveOrder,
  useFinalizeOrder,
  useGetAllProjects,
};
