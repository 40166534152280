import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getClientProjectsApi } from './api';

export const QUERY_KEY_CLIENT_PROJECTS = (id: string) => ['clientProjects', id];

export function useGetClientProjects(
  id: string,
  options?: InferQueryOptions<typeof getClientProjectsApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_CLIENT_PROJECTS(id),
    () => getClientProjectsApi({ id }),
    options,
  );

  return {
    clientProjects: data?.projects,
    ...rest,
  };
}
