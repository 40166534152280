/** @jsxImportSource theme-ui */
import React from 'react';
import * as ThemeUI from 'theme-ui';
import * as ReactRouter from 'react-router-dom';
import { Box, Flex } from 'components/layout';
import * as Icons from 'components/icons';
import { Text } from 'components/typography';
import { NavLink } from 'components/links';
import { Routing } from 'global/routing';
import { useModal } from 'store/modals';
import { CreateNewDocument } from 'components/CreateNewDocument/CreateNewDocument';
import { InviteUsersRequestEmailsRoleEnum } from 'trace-backend-sdk';

const BLACKLIST_ROUTES = [Routing.USERS.InviteUsers.path];

type MegaMenuProps = {
  role?: string;
};

export function MainMenu({ role }: MegaMenuProps) {
  const location = ReactRouter.useLocation();
  const { openModal } = useModal();

  const isHomeActive = location.pathname === Routing.HOME.getPath();
  const isDocumentsActive = location.pathname.includes(
    Routing.DOCUMENTS.default,
  );

  const shouldShowBottomMenu = !BLACKLIST_ROUTES.includes(location.pathname)
    && role !== InviteUsersRequestEmailsRoleEnum.Client;

  if (!shouldShowBottomMenu) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: 'inherit' }}>
      <Flex
        as="footer"
        sx={{
          width: '100%',
          fontSize: '2xl',
          backgroundColor: 'grey.100',
          justifyContent: 'space-around',
          boxShadow: '16dp',
          color: 'grey.400',
          zIndex: 'docked',
          isolation: 'isolate',

          '& > *': {
            flex: 1,
          },
        }}
      >
        <NavLink
          isActive={(match, location) => {
            if (!location) return false;
            return location.pathname === Routing.HOME.getPath();
          }}
          to={Routing.HOME.getPath()}
          exact
          variant="mainMenuItem"
        >
          <React.Fragment>
            {isHomeActive ? <Icons.HomeFilled /> : <Icons.Home />}
            <Text
              sx={{
                fontSize: 'xs',
              }}
              intlId="menu.main.home"
            />
          </React.Fragment>
        </NavLink>

        <ThemeUI.IconButton
          onClick={() => {
            openModal({
              modalProps: {
                children: <CreateNewDocument />,
              },
              modalType: 'bottomMenuModal',
            });
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            height: 'auto',
            isolation: 'isolate',
            position: 'relative',
            top: '-14px',
          }}
        >
          <Box
            sx={{
              width: 66,
              height: 66,
              display: 'flex',
              borderRadius: 'rounded',
              backgroundColor: 'secondary.500',
              fontSize: '4xl',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '4dp',
            }}
          >
            <Icons.AddButton />
          </Box>
        </ThemeUI.IconButton>
        <NavLink to={Routing.DOCUMENTS.default} variant="mainMenuItem">
          <React.Fragment>
            {isDocumentsActive ? (
              <Icons.DocumentsFilled />
            ) : (
              <Icons.Documents />
            )}
            <Text sx={{ fontSize: 'xs' }} intlId="menu.main.documents" />
          </React.Fragment>
        </NavLink>
      </Flex>
    </Box>
  );
}
