import axios from 'axios';
import { makeRequest, Service, identify } from 'utils/http';

type Data = {
  warehouseImage: File;
  warehouseId: string;
};

export async function uploadWarehouseImageApi(data: Data) {
  if (!data.warehouseId) return;

  const { updateImageUrl } = await makeRequest(
    Service.updateWarehouseImage(data.warehouseId, await identify()),
  );

  await axios.put(updateImageUrl, data.warehouseImage);
}
