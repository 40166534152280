import { sanitize, makeRequest, Service, identify } from 'utils/http';
import { UpdateDeliveryRequest } from 'trace-backend-sdk';

type GetUpdateDeliveryApiType = {
  deliveryID: string;
  deliveryData: UpdateDeliveryRequest;
};

export async function updateDeliveryAPI(data: GetUpdateDeliveryApiType) {
  return makeRequest(
    Service.updateDelivery(
      data.deliveryID,
      // @ts-expect-error Need to sanitize input, but it returns Dictionary<unknown>
      sanitize(data.deliveryData),
      await identify(),
    ),
  );
}
