import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { createOrderApi } from './api';

export function useCreateOrder(
  options?: InferMutationOptions<typeof createOrderApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'createOrder',
    createOrderApi,
    options,
  );

  return {
    createOrder: mutate,
    ...rest,
  };
}
