import { makeRequest, Service, identify } from 'utils/http';
import { FinalizeDeliveryRequest } from 'trace-backend-sdk';

type FinalizeDeliveryApiType = {
  deliveryId: string;
  finalizedData: FinalizeDeliveryRequest;
};

export async function finalizeDeliveryApi(data: FinalizeDeliveryApiType) {
  return makeRequest(
    Service.finalizeDelivery(
      data.deliveryId,
      data.finalizedData,
      await identify(),
    ),
  );
}
