import React from 'react';
import * as ReactIntl from 'lib/intl';
import * as ReactRouterDom from 'react-router-dom';
import * as ReactQuery from 'react-query';
import * as Utils from 'utils';

import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import { UserCard } from 'components/cards';
import { UserActivityStatus, UsersService } from 'services/users';
import * as Tabs from 'components/Tabs';
import * as Icons from 'components/icons';

import { IconButton } from 'components/form-elements/buttons/IconButton';
import { Routing } from 'global/routing';
import {
  GetAllUsersResponseUsers,
  UpdateUserWorkspaceRequestRoleEnum,
} from 'trace-backend-sdk';
import { useModal } from 'store/modals';
import { USERS_QUERY_KEY } from 'services/users/get-users';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ConfirmationDialog } from 'components/confirmation-dialog/ConfirmationDialog';
import { GenericFn } from 'utils/types';
import { Submenu } from 'components/submenu';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { LocaleKey } from 'translations';
import { useHistory } from 'react-router-dom';
import { useNotifications } from 'components/notifications/notifications';

function useUserStatusParam() {
  return ReactRouterDom.useParams<{ status: UserActivityStatus }>();
}

function getUsers(
  param: string,
  {
    active,
    pending,
    inactive,
  }: {
    active?: GetAllUsersResponseUsers[];
    pending?: GetAllUsersResponseUsers[];
    inactive?: GetAllUsersResponseUsers[];
  },
) {
  if (param === 'active') {
    return active ?? [];
  }
  if (param === 'pending') {
    return pending ?? [];
  }
  if (param === 'inactive') {
    return inactive ?? [];
  }
  return [];
}

export const Users: React.FC = () => {
  const { active, pending, inactive, allUsers } = UsersService.useGetUsers();

  const history = useHistory();
  const params = useUserStatusParam();

  const { currentUser, isLoading } = Utils.Auth.useCurrentAuthenticatedUser();

  if (!currentUser || isLoading) {
    return <PageLoadingIndicator />;
  }

  const {
    signInUserSession: {
      idToken: { payload },
    },
  } = currentUser;

  const adminUsers = allUsers?.filter((user) => user.role === 'admin') ?? [];

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 768,
        mx: 'auto',
      }}
    >
      <Flex flexDirection="column" gap={4}>
        <Tabs.Wrapper>
          <Tabs.Tab to={`${Routing.USERS.default}/active`}>
            <Text intlId="users.active" />
          </Tabs.Tab>

          <Tabs.Tab to={`${Routing.USERS.default}/pending`}>
            <Text intlId="users.pending" />
          </Tabs.Tab>

          <Tabs.Tab to={`${Routing.USERS.default}/inactive`}>
            <Text intlId="users.inactive" />
          </Tabs.Tab>
        </Tabs.Wrapper>

        {payload.role === 'admin' && (
          <Flex
            flexDirection="row" justifyContent="center"
            sx={{ px: 4 }}
          >
            <IconButton
              onClick={() => {
                history.push(Routing.USERS.InviteUsers.path);
              }}
              sx={{
                width: '100%',
                maxWidth: '400px',
                borderRadius: '100px',
                backgroundColor: 'secondary.500',
                px: 6,
                py: 4,
                height: 'auto',

                '.default': {
                  fill: 'none',
                  stroke: 'white.50',
                  strokeLinecap: 'round',
                  strokeLinejoin: 'bevel',
                  strokeWidth: '4px',
                },
              }}
            >
              <Flex
                flexDirection="row"
                gap={5}
                justifyContent="flex-start"
                alignItems="center"
              >
                <svg
                  className="default"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.25em"
                  viewBox="0 0 31.011 31.011"
                >
                  <g transform="translate(2 2)">
                    <line x1="27.011" transform="translate(0 13.505)" />
                    <line y1="27.011" transform="translate(13.505 0)" />
                  </g>
                </svg>

                <Text
                  sx={{
                    textTransform: 'uppercase',
                    color: 'white.50',
                    fontSize: 'md',
                    lineHeight: 1,
                  }}
                >
                  <ReactIntl.FormattedMessage id="users.inviteNewUsers" />
                </Text>
              </Flex>
            </IconButton>
          </Flex>
        )}
        <Flex
          flexDirection="column" gap={2}
          mb={50}
        >
          {getUsers(params.status, {
            active,
            pending,
            inactive,
          }).map((item, index) => (
            <UserCard key={`users.card-${index}_${item.userId}`}>
              <UserCard.Avatar
                firstName={item.user.firstName || ''}
                lastName={item.user.lastName}
                color={item.user.avatarColor}
                profileImageUrl={item.profileImageUrl}
                sx={{
                  flexShrink: 0,
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  boxShadow: 'userCard.elevation',
                  mr: 3,
                  ml: 0,
                  fontSize: 'xl',
                  position: 'relative',
                }}
              >
                {item.role === UpdateUserWorkspaceRequestRoleEnum.Admin && (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      backgroundColor: 'white.50',

                      position: 'absolute',
                      borderRadius: 9999,
                      color: 'tertiary2.800',
                      bottom: 0,
                      right: 0,
                      boxShadow: 1,
                    }}
                  >
                    <Icons.Star width={16} />
                  </Box>
                )}
              </UserCard.Avatar>

              <Flex sx={{ flexDirection: 'column' }}>
                <UserCard.Name variant="userCardName">
                  {item.user.firstName} {item.user.lastName}
                </UserCard.Name>
                <UserCard.Email
                  variant="cardLabelSmall"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.user.email}
                </UserCard.Email>
                <UserCard.Title variant="userCardTitle">
                  {item.role}
                </UserCard.Title>
              </Flex>
              {payload.role === 'admin' && (
                <Submenu>
                  <UserActions
                    currentUserId={payload.sub}
                    workspaceHasOneAdmin={adminUsers.length === 1}
                    userId={item.userId}
                    userActive={item.active}
                    userReady={item.ready}
                    userTitle={item.title}
                    // @ts-expect-error missing role in User Type in API schema
                    userRole={item.role}
                  />
                </Submenu>
              )}
            </UserCard>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

type UserActionsType = {
  userId: string;
  userTitle?: string;
  userActive: boolean;
  userReady: boolean;
  currentUserId: string;
  userRole: UpdateUserWorkspaceRequestRoleEnum;
  workspaceHasOneAdmin: boolean;
};

const UserActions = ({
  userId,
  userTitle = '', // TODO: fix after a prop will be added to User type
  userActive,
  userReady,
  userRole,
  workspaceHasOneAdmin,
  currentUserId,
}: UserActionsType) => {
  const queryClient = ReactQuery.useQueryClient();
  const { refreshSession } = Utils.Auth.useRefreshSession();
  const { openModal } = useModal();
  const history = useHistory();

  const { deleteInvitedUser } = UsersService.useDeleteInvitedUser({
    onSuccess: async () => {
      await queryClient.invalidateQueries(USERS_QUERY_KEY);
    },
  });
  const notifications = useNotifications();
  const { updateUser, isLoading: isLoadingUpdateUsers } = UsersService.useUpdateUser({
    onSuccess: async (data, variables) => {
      if (variables.userData.active) {
        notifications.success({
          description: 'user.reactivate.success',
          durationMs: 'Infinity',
        });
      }
      if (variables.userID !== currentUserId) {
        await queryClient.invalidateQueries(USERS_QUERY_KEY);
        return;
      }

      await refreshSession();
    },
  });

  const { resendInvitation, isLoading: isLoadingResend } = UsersService.useResendInvitation({
    onSuccess: async () => {
      await history.push(
        `${Routing.TRANSITIONS.SuccessConfirmation}?key=inviteUsers.success`,
      );
    },
  });

  function onConfirmAction(onProceed: GenericFn, confirmText?: LocaleKey) {
    openModal({
      modalType: 'componentModal',
      modalProps: {
        children: (
          <ConfirmationDialog onProceed={onProceed}>
            <Box sx={{ textAlign: 'center' }}>
              <Text intlId={confirmText ?? 'generic.confirm.areYouSure'} />
            </Box>
          </ConfirmationDialog>
        ),
      },
    });
  }

  function onInactivateAdminUser() {
    openModal({
      modalType: 'componentModal',
      modalProps: {
        children: (
          <ConfirmationDialog
            disabled={workspaceHasOneAdmin}
            proceedText="users.inactivateAdmin.proceedText"
            onProceed={() => {
              updateUser({
                userID: userId,
                userData: {
                  title: userTitle,
                  // @ts-expect-error Change to backend produced enums
                  role: 'user',
                  active: userActive,
                },
              });
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Text intlId="users.inactivateAdmin.confirm" />
            </Box>
          </ConfirmationDialog>
        ),
      },
    });
  }

  return (
    <React.Fragment>
      {(isLoadingUpdateUsers || isLoadingResend) && (
        <Flex
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingIndicator sx={{ fontSize: '2em' }} />
        </Flex>
      )}

      {(() => {
        if (userActive && userReady) {
          return (
            <React.Fragment>
              {userId !== currentUserId && (
                <Submenu.Item
                  prependIcon={(
                    <IconWrapper width={22}>
                      <Icons.Checkbox width={18} />
                    </IconWrapper>
                  )}
                  onClick={() => {
                    if (userRole === 'admin') {
                      onInactivateAdminUser();
                      return;
                    }

                    onConfirmAction(() =>
                      updateUser({
                        userID: userId,
                        userData: {
                          title: userTitle,
                          role: userRole,
                          active: false,
                        },
                      }));
                  }}
                >
                  <Text intlId="users.actions.inactivate" />
                </Submenu.Item>
              )}

              <Submenu.Item
                disabled={userRole === 'admin' && workspaceHasOneAdmin}
                prependIcon={(
                  <IconWrapper width={22}>
                    <Icons.UserWithoutBackground width={15} />
                  </IconWrapper>
                )}
                onClick={() =>
                  onConfirmAction(() =>
                    updateUser({
                      userID: userId,
                      userData: {
                        title: userTitle,
                        // TODO
                        // @ts-expect-error Change to backend produced enums
                        role: userRole === 'admin' ? 'user' : 'admin',
                        active: userActive,
                      },
                    }))}
              >
                <Text
                  intlId={
                    userRole === 'admin'
                      ? 'users.actions.removeAdmin'
                      : 'users.actions.setAsAdmin'
                  }
                />
              </Submenu.Item>
            </React.Fragment>
          );
        }

        if (!userActive && userReady) {
          return (
            <Submenu.Item
              prependIcon={<Icons.Checkbox />}
              onClick={() =>
                onConfirmAction(() =>
                  updateUser({
                    userID: userId,
                    userData: {
                      title: userTitle,
                      role: userRole,
                      active: true,
                    },
                  }))}
            >
              <Text intlId="users.actions.activate" />
            </Submenu.Item>
          );
        }

        if (!userReady) {
          return (
            <React.Fragment>
              <Submenu.Item
                prependIcon={<Icons.Checkbox />}
                onClick={() => onConfirmAction(() => resendInvitation(userId))}
              >
                <Text intlId="users.actions.resendInvitation" />
              </Submenu.Item>

              <Submenu.Item
                prependIcon={<Icons.Checkbox />}
                onClick={() =>
                  onConfirmAction(() => deleteInvitedUser({ userId }))}
              >
                <Text intlId="users.actions.deleteInvitedUser" />
              </Submenu.Item>
            </React.Fragment>
          );
        }

        return null;
      })()}
    </React.Fragment>
  );
};

const IconWrapper = ({
  width,
  children,
}: {
  width?: number;
  children?: React.ReactNode;
}) => (
  <Flex
    justifyContent="center" alignItems="center"
    sx={{ width }}
  >
    {children}
  </Flex>
);
