import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { rejectDeliveryApi } from './api';

export function useRejectDelivery(
  options?: InferMutationOptions<typeof rejectDeliveryApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'reject-delivery',
    rejectDeliveryApi,
    options,
  );

  return {
    rejectDelivery: mutate,
    ...rest,
  };
}
