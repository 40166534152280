import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getWorkspaceClientsApi } from './api';

export const QUERY_KEY_WORKSPACE_CLIENTS = ['workspaceClients'];

export function useGetWorkspaceClients(
  options?: InferQueryOptions<typeof getWorkspaceClientsApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_WORKSPACE_CLIENTS,
    getWorkspaceClientsApi,
    options,
  );

  return {
    workspaceClients: data?.clients,
    ...rest,
  };
}
