import axios from 'axios';
import { makeRequest, Service, identify } from 'utils/http';

type Data = {
  itemImage: File;
  itemId: string;
};

export async function uploadItemImageApi(data: Data) {
  if (!data.itemId) return;

  const { updateImageUrl } = await makeRequest(
    Service.updateItemImage(data.itemId, await identify()),
  );

  await axios.put(updateImageUrl, data.itemImage);
}
