import React from 'react';
import * as Utils from 'utils';
import { Helmet } from 'react-helmet';
import { Routes } from 'app/Routes';
import { isIOS } from 'utils/client';
import { useResetLsmStore } from 'store/utils';
import { Notifications } from 'components/notifications/notifications';
import { GlobalState } from './components/seo/GlobalState';
import { CookieConsentBanner } from './utils/analytics/CookieConsentBanner';

function App() {
  Utils.Auth.useAmplifyStateListener();
  Utils.Analytics.useGoogleAnalyticsRouting();
  useResetLsmStore();

  return (
    <GlobalState>
      <Helmet>
        <meta
          name="viewport"
          content={`width=device-width, initial-scale=1${
            isIOS && ', maximum-scale=1'
          }`}
        />
      </Helmet>

      <Notifications />
      <Routes />
      <CookieConsentBanner />
    </GlobalState>
  );
}

export default App;
