import * as React from 'react';
import { WarehouseService } from 'services/warehouse';
import { InviteUsersRequestEmailsRoleEnum, Order } from 'trace-backend-sdk';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { Text } from 'components/typography';
import { Box, Container } from '../../../../components/layout';
import { WarehouseOrdersCard } from './components/WarehouseOrdersCard/WarehouseOrdersCard';

type WarehouseOrdersProps = {
  role: string;
};

export const WarehouseOrders = ({ role }: WarehouseOrdersProps) => {
  const { allOrders, isLoading } = WarehouseService.useGetAllOrders();
  if (isLoading) {
    return <InlineLoadingIndicator />;
  }
  return (
    <Container
      variant="container"
      sx={{
        p: 0,
      }}
    >
      {allOrders && allOrders?.length > 0 ? (
        <Box>
          {allOrders
            ?.filter((order: Order) => {
              return (
                role !== InviteUsersRequestEmailsRoleEnum.User
                || order.status !== 'SCHEDULED'
              );
            })
            .map((order: Order) => (
              <WarehouseOrdersCard
                order={order}
                key={`${order.id}`}
                role={role}
              />
            ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            intlId="tab.orders.noOrders.message"
            sx={{ textAlign: 'center', fontStyle: 'italic', width: 'full' }}
          />
        </Box>
      )}
    </Container>
  );
};
