import * as React from 'react';
import { useModal } from 'store/modals';
import { BaseModal } from 'components/modals/BaseModal';

export function WideComponentModal({
  children,
}: {
  children: React.ReactNode;
}) {
  const { closeModal } = useModal();

  return (
    <BaseModal
      onClose={() => closeModal()}
      open
      contentSx={{
        borderRadius: '8px',
        boxShadow: '4dp',
        width: '100%',
        maxWidth: '768px',
      }}
    >
      {children}
    </BaseModal>
  );
}
