import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getDeliveryApi } from './api';

export const QUERY_KEY_DELIVERY = (id: string) => ['delivery', id];

export function useGetDelivery(
  id: string,
  options?: InferQueryOptions<typeof getDeliveryApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_DELIVERY(id),
    () => getDeliveryApi({ id }),
    options,
  );

  return {
    delivery: data,
    ...rest,
  };
}
