/** @jsxImportSource theme-ui */
import React, { useState } from 'react';
import * as Lsm from 'little-state-machine';
import { useModal } from 'store/modals';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Link } from 'components/links';
import { Routing } from 'global/routing';
import { WorkspaceService } from 'services/workspace';
import * as Icons from 'components/icons';
import { SidebarActions } from 'store';
import { WorkspaceLogo } from 'components/WorkspaceLogo/WorkspaceLogo';
import { DocumentStatusEnum, WorkspaceStatusEnum } from 'trace-backend-sdk';
import { Avatar } from 'components/avatar';
import { UsersService } from 'services/users';
import { useIntl } from 'lib/intl';
import { Drawer } from 'components/Drawer/Drawer';
import Popup from 'reactjs-popup';
import * as ThemeUI from 'theme-ui';

import { StyleObject } from 'theming';
import { FocusThief } from 'components/FocusThief/FocusThief';
import { GenericFn } from 'utils/types';
import { useWindowWidth } from 'utils/hooks/use-window-width';
import { TextVariants } from 'theming/variants/text';
import { getSelectedWarehouse } from 'utils/warehouse-management/get-selected-warehouse';
import { ChooseLanguage } from '../../../features/language/components/ChooseLanguage';
import { Authorization } from '../../authorization/Authorization';
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from '../../TermsAndConditionsText/TermsAndConditionsText';
import {
  SideMenuFooter,
  MenuItemWithIcon,
  SideMenuFooterMenuHeading,
} from './components/SideMenuFooter/SideMenuFooter';

export function SideMenuDropdownLink({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex
      sx={{
        px: 5,
        py: 4,
        fontSize: 'sm',
        '& > *:first-of-type': {
          mr: 5,
        },
      }}
    >
      {children}
    </Flex>
  );
}

function AccordionIcon({
  children,
  iconSx,
}: {
  children: React.ReactNode;
  iconSx?: StyleObject;
}) {
  return (
    <Box
      sx={{
        alignSelf: 'flex-start',
        mt: '1px',
        flexShrink: 0,
        transition: 'all 0.75s 0.05s',
        transform: 'rotate(0)',
        ...iconSx,
      }}
    >
      {children}
    </Box>
  );
}

function AccordionContent({ children }: { children: React.ReactNode }) {
  return <Flex sx={{ flexDirection: 'column', ml: 3 }}>{children}</Flex>;
}

function Accordion({
  isDefaultOpen = false,
  title,
  icon,
  iconSx,
  triggerSx,
  children,
}: {
  isDefaultOpen?: boolean;
  title: string;
  icon: React.ReactNode;
  iconSx?: StyleObject;
  triggerSx?: StyleObject;
  children: React.ReactNode | React.ReactNodeArray;
}) {
  const [isOpen, setIsOpen] = React.useState(isDefaultOpen);

  return (
    <Flex
      sx={{
        alignItems: 'center',
      }}
    >
      <AccordionIcon iconSx={iconSx}>{icon}</AccordionIcon>

      <AccordionContent>
        <ThemeUI.Button
          onClick={() => {
            setIsOpen((o) => !o);
          }}
          variant="text"
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0,
            textAlign: 'start',

            ...(isOpen && {
              mb: 3,
            }),

            ...triggerSx,
          }}
        >
          <SideMenuItemTitle
            title={title}
            sxTitle={{
              mr: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />

          <Flex
            sx={{
              alignSelf: 'flex-start',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              position: 'relative',
              top: '1px',
            }}
          >
            <Icons.ChevronDown width="14px" color="secondary.500" />
          </Flex>
        </ThemeUI.Button>

        {isOpen && children}
      </AccordionContent>
    </Flex>
  );
}

function SideMenuItemTitle({
  title,
  variant = 'sideMenu',
  sxTitle,
}: {
  title: string;
  variant?: TextVariants;
  sxTitle?: StyleObject;
}) {
  return (
    <Text
      sx={{
        fontSize: 'lg',
        position: 'relative',
        '@media screen and (min-width: 1280px)': {
          ':after': {
            content: '""',
            display: 'block',
            height: '2px',
            left: '50%',
            position: 'absolute',
            backgroundColor: 'secondary.500',
            transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
            width: '0',
          },
          ':hover:after': {
            width: '100%',
            left: '0',
          },
        },
        ...sxTitle,
      }}
      variant={variant}
    >
      {title}
    </Text>
  );
}

function SideMenuUi({
  drawerWidth,
  onSideMenuClose = () => {},
}: {
  drawerWidth: number;
  onSideMenuClose?: GenericFn;
}) {
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const { loggedInUser } = UsersService.useGetLoggedInUser();
  const [isFooterMenuOpen, setIsFooterMenuOpen] = React.useState(false);
  const { openModal } = useModal();
  const [isTextOverflowHidden, setIsTextOverflowHidden] = useState(true);

  return (
    <React.Fragment>
      <SideMenuHeader
        onClose={onSideMenuClose}
        drawerWidth={drawerWidth}
        currentUserRole={loggedInUser?.role}
        organizationName={workspaceData?.organizationName}
      />

      <ThemeUI.Divider sx={{ borderColor: 'grey.200', mb: 4 }} />

      <SideMenuBody onClose={onSideMenuClose} />

      <SideMenuFooter>
        {loggedInUser && (
          <React.Fragment>
            <SideMenuFooterMenuHeading
              toggleFooterMenuVisibility={() => {
                setIsFooterMenuOpen((prevState) => !prevState);
                setIsTextOverflowHidden((prevState) => !prevState);
              }}
              title={[
                loggedInUser.user.firstName,
                loggedInUser.user.lastName,
              ].join(' ')}
              icon={(
                <Avatar
                  profileImageUrl={loggedInUser.userImageUrl}
                  firstName={loggedInUser.user.firstName}
                  lastName={loggedInUser.user.lastName}
                  active={loggedInUser.active}
                  color={loggedInUser.user.avatarColor}
                />
              )}
              chevronIcon={
                <Icons.ChevronDown width="14px" color="secondary.500" />
              }
              isTextOverflowHidden={isTextOverflowHidden}
            />
            {isFooterMenuOpen && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mt: 3,
                }}
              >
                <MenuItemWithIcon
                  intlId="menu.side.userProfile"
                  to={Routing.PROFILE.View}
                  onClick={onSideMenuClose}
                  icon={<Icons.Edit color="grey.400" width={20} />}
                />
                <MenuItemWithIcon
                  intlId="menu.side.chooseLanguage"
                  onClick={() =>
                    openModal({
                      modalType: 'componentModal',
                      modalProps: {
                        children: <ChooseLanguage />,
                      },
                    })}
                  icon={<Icons.LanguageSwitcher color="grey.400" size={20} />}
                />
                <MenuItemWithIcon
                  intlId="menu.side.logout"
                  to={Routing.SIGN_OUT.getPath()}
                  icon={<Icons.LogoutNew color="grey.400" size={20} />}
                />
              </Box>
            )}
          </React.Fragment>
        )}
      </SideMenuFooter>
    </React.Fragment>
  );
}

function SideMenuHeader({
  drawerWidth,
  currentUserRole,
  organizationName,
  onClose,
}: {
  organizationName: string | undefined;
  drawerWidth: number;
  onClose: GenericFn;
  currentUserRole: string | undefined;
}) {
  const [isTextOverflowHidden, setIsTextOverflowHidden] = useState(true);
  return (
    <Popup
      arrow={false}
      position="bottom left"
      nested
      onClose={() => setIsTextOverflowHidden(true)}
      trigger={(
        <ThemeUI.Button
          variant="text"
          sx={{
            cursor: 'pointer',
            py: 6,
            px: 5,
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:last-child)': {
              mr: 3,
            },
          }}
          onFocus={() => setIsTextOverflowHidden(false)}
        >
          <WorkspaceLogo size={40} />

          <Text
            variant={isTextOverflowHidden ? 'singleLine' : 'multiLine'}
            sx={{
              color: 'primary.900',
              fontSize: isTextOverflowHidden ? '16px' : '14px',
              fontWeight: '500',
            }}
          >
            {organizationName}
          </Text>

          <Icons.ChevronDownFilled sx={{ fill: 'primary.900' }} width={15} />
        </ThemeUI.Button>
      )}
      sx={{
        '&-content': {
          backgroundColor: '#f5f5f5',
          width: drawerWidth,

          border: '1px solid',
          borderColor: '#D1D2D3',
          borderLeft: 'none',
          borderRight: 'none',

          filter: 'drop-shadow(0px 4px 8px #00000012)',
        },
      }}
    >
      {currentUserRole === 'admin' && (
        <Link
          onClick={onClose} variant="basic"
          to={Routing.SETTINGS.getPath()}
        >
          <SideMenuDropdownLink>
            <Icons.Edit width={20} />
            <Text variant="sideMenuLight" intlId="menu.side.editWorkspace" />
          </SideMenuDropdownLink>
        </Link>
      )}

      <Link variant="basic" to={Routing.SIGN_OUT.getPath()}>
        <SideMenuDropdownLink>
          <Icons.Logout color="grey.400" width={20} />
          <Text variant="sideMenuLight" intlId="menu.side.logout" />
        </SideMenuDropdownLink>
      </Link>

      <ThemeUI.Divider sx={{ m: 0, borderColor: 'grey.200' }} />

      <SideMenuDropdownLink>
        <Icons.TermsAndPrivacy color="grey.400" width={20} />

        <Flex>
          <PrivacyPolicyLink>
            <Text variant="sideMenuLight" intlId="menu.side.privacy" />
          </PrivacyPolicyLink>

          <Box sx={{ width: '1px', backgroundColor: 'grey.300', mx: 3 }} />

          <TermsAndConditionsLink>
            <Text variant="sideMenuLight" intlId="menu.side.terms" />
          </TermsAndConditionsLink>
        </Flex>
      </SideMenuDropdownLink>
    </Popup>
  );
}

function SideMenuBody({ onClose }: { onClose: GenericFn }) {
  const intl = useIntl();

  const selectedWarehouse = getSelectedWarehouse();
  return (
    <Box
      sx={{
        pl: 8,
        overflow: 'auto',

        '& > *': {
          py: 4,
        },
      }}
    >
      {/* Home */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AccordionIcon>
          <Icons.Home color="primary.900" width={21} />
        </AccordionIcon>
        <Link
          onClick={onClose}
          sx={{ display: 'flex' }}
          variant="basic"
          to={Routing.HOME.getPath()}
        >
          <AccordionContent>
            <SideMenuItemTitle
              title={intl.formatMessage({
                id: 'menu.side.home',
              })}
            />
          </AccordionContent>
        </Link>
      </Box>

      {/* Documents */}
      <Authorization roles={['admin', 'user']}>
        <Accordion
          isDefaultOpen
          title={intl.formatMessage({ id: 'menu.side.documents' })}
          icon={<Icons.Documents color="primary.900" width={21} />}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              '& > *': {
                py: 1,
              },
            }}
          >
            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.DOCUMENTS.Links.DocumentsList({
                status: DocumentStatusEnum.Draft,
              })}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({ id: 'menu.side.documents.draft' })}
                variant="sideMenuLight"
              />
            </Link>

            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.DOCUMENTS.Links.DocumentsList({
                status: DocumentStatusEnum.Assigned,
              })}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({
                  id: 'menu.side.documents.assigned',
                })}
                variant="sideMenuLight"
              />
            </Link>

            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.DOCUMENTS.Links.DocumentsList({
                status: DocumentStatusEnum.Completed,
              })}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({
                  id: 'menu.side.documents.completed',
                })}
                variant="sideMenuLight"
              />
            </Link>
          </Flex>
        </Accordion>
      </Authorization>

      {/* Warehouse Module */}

      <Accordion
        isDefaultOpen
        title={intl.formatMessage({ id: 'home.cards.warehouseManagement' })}
        icon={<Icons.Building2 width={24} color="primary.900" />}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            '& > *': {
              py: 1,
            },
          }}
        >
          {selectedWarehouse && selectedWarehouse.id ? (
            <Link
              onClick={onClose}
              variant="basic"
              to={`/warehouse/${selectedWarehouse.id}`}
            >
              <SideMenuItemTitle
                title={`My warehouse: ${selectedWarehouse.name}`}
                variant="sideMenuLight"
              />
            </Link>
          ) : (
            <Text sx={{ color: 'grey.300', fontWeight: 200 }}>
              Select a Warehouse
            </Text>
          )}

          <Link
            onClick={onClose}
            variant="basic"
            to={Routing.WAREHOUSES.getPath()}
          >
            <SideMenuItemTitle
              title={intl.formatMessage({ id: 'home.cards.warehouses' })}
              variant="sideMenuLight"
            />
          </Link>

          <Link
            onClick={onClose}
            variant="basic"
            to={Routing.PROJECTS.getPath()}
          >
            <SideMenuItemTitle
              title={intl.formatMessage({ id: 'home.cards.projects' })}
              variant="sideMenuLight"
            />
          </Link>

          <Link
            onClick={onClose}
            variant="basic"
            to={Routing.CALENDAR.getPath()}
          >
            <SideMenuItemTitle
              title={intl.formatMessage({
                id: 'home.cards.calendar',
              })}
              variant="sideMenuLight"
            />
          </Link>
        </Flex>
      </Accordion>

      {/* Users */}
      <Accordion
        isDefaultOpen
        title={intl.formatMessage({ id: 'menu.side.users' })}
        icon={<Icons.Users color="primary.900" width={21} />}
        iconSx={{ position: 'relative', top: '3px' }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            '& > *': {
              py: 1,
            },
          }}
        >
          <Link
            onClick={onClose} variant="basic"
            to={Routing.USERS.default}
          >
            <SideMenuItemTitle
              title={intl.formatMessage({ id: 'users.viewUsers' })}
              variant="sideMenuLight"
            />
          </Link>

          <Authorization roles={['admin']}>
            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.USERS.InviteUsers.path}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({ id: 'users.inviteNewUsers' })}
                variant="sideMenuLight"
              />
            </Link>
          </Authorization>
        </Flex>
      </Accordion>

      {/* Modules */}
      <Authorization roles={['admin']}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccordionIcon>
            <Icons.Addons />
          </AccordionIcon>
          <Link
            onClick={onClose}
            sx={{ display: 'flex' }}
            variant="basic"
            to={Routing.PERMISSIONS.Default.path}
          >
            <AccordionContent>
              <SideMenuItemTitle
                title={intl.formatMessage({
                  id: 'menu.side.products',
                })}
              />
            </AccordionContent>
          </Link>
        </Box>
      </Authorization>

      {/* Billing */}
      <Authorization
        workspaceStatuses={[
          { resource: WorkspaceStatusEnum.ForeverFree, action: 'deny' },
        ]}
        roles={['admin']}
      >
        <Accordion
          isDefaultOpen
          iconSx={{ position: 'relative', top: '3px', ml: '-2px' }}
          title={intl.formatMessage({ id: 'billing.sideMenu.title' })}
          icon={<Icons.Card color="primary.900" width={23} />}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              '& > *': {
                py: 1,
              },
            }}
          >
            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.PAYMENTS.UpcomingPayment.path}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({ id: 'page.upcomingPayment' })}
                variant="sideMenuLight"
              />
            </Link>

            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.PAYMENTS.PaymentHistory.path}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({ id: 'page.paymentHistory' })}
                variant="sideMenuLight"
              />
            </Link>

            <Link
              onClick={onClose}
              variant="basic"
              to={Routing.PAYMENTS.ManagePaymentInfo.path}
            >
              <SideMenuItemTitle
                title={intl.formatMessage({
                  id: 'billing.sideMenu.managePayment',
                })}
                variant="sideMenuLight"
              />
            </Link>
          </Flex>
        </Accordion>
      </Authorization>
    </Box>
  );
}

export function SideMenu() {
  const [drawerWidth, setDrawerWidth] = React.useState(0);

  const { state, actions } = Lsm.useStateMachine({
    closeSideBar: SidebarActions.closeSideBar,
  });

  function onSideMenuClose() {
    actions.closeSideBar(undefined);
  }

  const windowWidth = useWindowWidth();

  if (windowWidth < 1024) {
    return (
      <Drawer
        onResize={({ width }) => setDrawerWidth(width)}
        isOpen={state.isSideBarOpen}
        onClose={() => onSideMenuClose()}
      >
        <FocusThief />

        <SideMenuUi
          drawerWidth={drawerWidth}
          onSideMenuClose={() => onSideMenuClose()}
        />
      </Drawer>
    );
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: 350,
        maxWidth: 350,
        backgroundColor: 'bg.50',
        boxShadow: '4dp',
        zIndex: 1,
      }}
    >
      <SideMenuUi drawerWidth={350} />
    </Flex>
  );
}
