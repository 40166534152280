import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { finalizeOrderApi } from './api';

export function useFinalizeOrder(
  options?: InferMutationOptions<typeof finalizeOrderApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'finalize-order',
    finalizeOrderApi,
    options,
  );

  return {
    finalizeOrder: mutate,
    ...rest,
  };
}
