import { FinalizeOrderRequest } from 'trace-backend-sdk';
import { identify, makeRequest, Service } from '../../../utils/http';

type FinalizeOrderApiType = {
  orderId: string;
  finalizedData: FinalizeOrderRequest;
};

export async function finalizeOrderApi(data: FinalizeOrderApiType) {
  return makeRequest(
    Service.finalizeOrder(data.orderId, data.finalizedData, await identify()),
  );
}
