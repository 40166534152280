import Joi from 'joi';

export const PACKED_QUANTITY = Joi.number()
  .min(0)
  .required()
  .empty('')
  .messages({
    'any.required': 'processDelivery.form.input.error.required',
    'number.base': 'processDelivery.form.input.error.numbersOnly',
    'number.min': 'processDelivery.form.input.error.required',
  });

export const NOTES = Joi.any();
