import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getAllOrdersApi } from './api';

export const QUERY_KEY_ALL_ORDERS = ['allOrders'];

export function useGetAllOrders(
  options?: InferQueryOptions<typeof getAllOrdersApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ALL_ORDERS,
    getAllOrdersApi,
    options,
  );

  return {
    allOrders: data?.orders,
    ...rest,
  };
}
