import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { getOrderDocumentApi } from './api';

const QUERY_KEY_ORDER_DOCUMENT = (orderId: string) => [
  'orderDocument',
  orderId,
];

export function useGetOrderDocument(
  orderId: string,
  options?: InferQueryOptions<typeof getOrderDocumentApi>,
) {
  const { data: orderDocumentUrl, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ORDER_DOCUMENT(orderId),
    () => getOrderDocumentApi(orderId),
    options,
  );

  return {
    orderDocumentUrl,
    ...rest,
  };
}
