import * as React from 'react';

import * as Utils from 'utils';
import { Text } from 'components/typography';
import { Container, Grid } from 'components/layout';
import { HomepageCard } from 'components/cards';
import { DocumentsService } from 'services/documents';
import { useRedirect } from 'utils/redirect';
import Active from 'assets/images/Active.svg';
import Assigned from 'assets/images/Assigned.svg';
import Completed from 'assets/images/Completed.svg';
import { Routing } from 'global/routing';
import {
  DocumentStatusEnum,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import Calendar from 'assets/images/Calendar.svg';
import Projects from 'assets/images/Projects-folder.svg';
import { WarehouseService } from 'services/warehouse';
import { Authorization } from 'components/authorization/Authorization';
import Warehouses from '../../assets/images/Warehouse-building.svg';
import { ShouldRender } from '../../components/ShouldRender';

export const Home: React.FC = () => {
  const { isSuccess: isCurrentAuthenticatedUserSuccess } = Utils.Auth.useCurrentAuthenticatedUser();
  const { onRedirect } = useRedirect(Routing.HOME.getPath());
  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();

  const {
    totalDraftsDocuments,
    totalCompletedDocuments,
    totalAssignedDocuments,
  } = DocumentsService.useGetDocuments({
    enabled:
      Boolean(isCurrentAuthenticatedUserSuccess)
      && currentUser?.signInUserSession.idToken.payload.role
        !== InviteUsersRequestEmailsRoleEnum.Client,
  });
  const { totalWarehouses } = WarehouseService.useGetAllWarehouses();

  const clientHasWarehouseModule = true;

  return (
    <Container variant="spacer">
      <Grid
        sx={{
          width: '100%',
        }}
      >
        <Authorization roles={['admin', 'user']}>
          <Text
            sx={{
              width: '100%',
            }}
            variant="homeSubtitle"
            intlId="home.cards.draftDocuments.subtitle"
          />

          <Grid sx={{ gridAutoFlow: 'column', mb: 5 }}>
            <HomepageCard
              sx={{
                backgroundColor: 'tertiary1.050',
              }}
              onClick={() =>
                onRedirect(
                  Routing.DOCUMENTS.Links.DocumentsList({
                    status: DocumentStatusEnum.Draft,
                  }),
                )}
            >
              <HomepageCard.Image
                src={Active}
                alt="active"
                sx={{
                  width: '30px',
                  my: 2,
                }}
              />

              <HomepageCard.Text
                intlId="home.cards.draftDocuments.label"
                variant="subtitle2"
              />

              <HomepageCard.Label
                itemNumber={totalDraftsDocuments}
                intlId="home.cards.documents"
              />
            </HomepageCard>

            <HomepageCard
              sx={{
                backgroundColor: 'secondary.050',
              }}
              onClick={() =>
                onRedirect(
                  Routing.DOCUMENTS.Links.DocumentsList({
                    status: DocumentStatusEnum.Assigned,
                  }),
                )}
            >
              <HomepageCard.Image
                src={Assigned}
                alt="assigned"
                sx={{
                  width: '30px',
                  my: 2,
                }}
              />

              <HomepageCard.Text
                intlId="home.cards.assignedDocuments.label"
                variant="subtitle2"
              />

              <HomepageCard.Label
                itemNumber={totalAssignedDocuments}
                intlId="home.cards.documents"
              />
            </HomepageCard>
          </Grid>

          <Text
            sx={{
              width: '100%',
            }}
            variant="homeSubtitle"
            intlId="home.cards.completedDocuments.subtitle"
          />

          <Grid sx={{ gridAutoFlow: 'column', mb: 5 }}>
            <HomepageCard
              sx={{
                height: '125px',
                justifyContent: 'flex-start',
              }}
              onClick={() =>
                onRedirect(
                  Routing.DOCUMENTS.Links.DocumentsList({
                    status: DocumentStatusEnum.Completed,
                  }),
                )}
            >
              <HomepageCard.Text
                intlId="home.cards.completedDocuments.label"
                variant="subtitle2"
              />

              <HomepageCard.Label
                itemNumber={totalCompletedDocuments}
                intlId="home.cards.documents"
              />

              <HomepageCard.Image
                src={Completed}
                alt="assigned"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              />
            </HomepageCard>
          </Grid>
        </Authorization>
        <ShouldRender when={clientHasWarehouseModule}>
          <Text
            sx={{
              width: '100%',
            }}
            variant="homeSubtitle"
            intlId="home.cards.warehouseManagement"
          />

          <Grid sx={{ gridAutoFlow: 'column' }}>
            <HomepageCard
              sx={{
                backgroundColor: 'secondary.050',
              }}
              onClick={() => onRedirect(Routing.WAREHOUSES.getPath())}
            >
              <HomepageCard.Image
                src={Warehouses}
                alt="active"
                sx={{
                  width: '30px',
                  my: 2,
                }}
              />

              <HomepageCard.Text
                intlId="home.cards.warehouses"
                variant="subtitle2"
              />

              <HomepageCard.Label
                itemNumber={totalWarehouses}
                intlId="home.cards.warehouses"
              />
            </HomepageCard>

            <HomepageCard
              sx={{
                backgroundColor: 'primary.050',
              }}
              onClick={() => onRedirect(Routing.PROJECTS.getPath())}
            >
              <HomepageCard.Image
                src={Projects}
                alt="assigned"
                sx={{
                  width: '30px',
                  my: 2,
                }}
              />

              <HomepageCard.Text
                intlId="home.cards.projects"
                variant="subtitle2"
              />

              <HomepageCard.Label
                itemNumber={totalAssignedDocuments}
                intlId="home.cards.documents"
              />
            </HomepageCard>
          </Grid>

          <Grid sx={{ gridAutoFlow: 'column' }}>
            <HomepageCard
              sx={{
                backgroundColor: 'tertiary1.050',
              }}
              onClick={() => onRedirect(Routing.CALENDAR.getPath())}
            >
              <HomepageCard.Image
                src={Calendar}
                alt="assigned"
                sx={{
                  width: '30px',
                  my: 2,
                }}
              />

              <HomepageCard.Text
                intlId="home.cards.calendar"
                variant="subtitle2"
              />

              <HomepageCard.Label
                itemNumber={totalAssignedDocuments}
                intlId="home.cards.tasks"
              />
            </HomepageCard>
          </Grid>
        </ShouldRender>
      </Grid>
    </Container>
  );
};
