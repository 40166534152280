import { createTypedVariant, StyleObject } from 'theming';
import { text } from './text';

export type ButtonVariants = keyof typeof buttons;

const buttonBase = {
  borderRadius: '4px',
  py: '14px',
  width: '100%',
  ...text['button.text'],

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.5',
    backgroundColor: 'grey.400',
  },
} as StyleObject;

export const buttons = createTypedVariant({
  primary: {
    ...buttonBase,
    backgroundColor: 'tertiary1.300',
  },
  secondary: {
    ...buttonBase,
    backgroundColor: 'secondary.400',
  },
  preview: {
    ...buttonBase,
    border: '1px solid #21429E',
    color: 'secondary.900',
    backgroundColor: 'grey.100',
  },
  text: {
    color: 'currentColor',
    fontSize: 16,
    backgroundColor: 'transparent',

    '&:disabled': {
      opacity: 0.5,
    },
  },
  zoom: {
    backgroundColor: 'bg.50',
    color: 'secondary.500',
    fontSize: '3xl',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
  },
  quickTrace: {
    createWidget: {
      py: 3,
      display: 'flex',
      alignItems: 'center',
      color: 'darkgrey.800',
      fontWeight: 500,
      fontSize: 'md',
      backgroundColor: 'transparent',
    },
  },
  iconLabel: {
    width: 'auto',
    height: 'auto',
    flexDirection: 'column',
    fontSize: 'sm',
  },
});
